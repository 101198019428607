import axios, { AxiosRequestConfig } from 'axios';

import { AccountDto } from '@alpha/auth-dtos';
import { datadogRum } from '@datadog/browser-rum';

import environmentVars from '../../env.variables';
import AuthService from '../Auth/auth.service';

import AuthError from './AuthError';
import NetworkError from './NetworkError';

export const instance = axios.create({
  baseURL: environmentVars.REACT_APP_API_URL,
});

instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const newConfig = { ...config };
  newConfig.headers.Authorization = await AuthService.getAccessToken();
  newConfig.headers['session-id'] = AuthService.getSessionIdFromLocalStorage();
  if (!config.headers['account-id']) {
    try {
      newConfig.headers['account-id'] = (JSON.parse(localStorage.getItem('account-info') || '') as AccountDto)?.id || '';
    } catch (e) {
      newConfig.headers['account-id'] = '';
    }
  }
  return newConfig;
});

instance.interceptors.response.use((response) => response, async (error) => {
  // Handles 2 kinds of errors here by default: Auth error and network error
  // Note that Application logic permission 403 errors should be handled per API request

  // Remove access token
  if (error?.response?.config?.headers) { error.response.config.headers.Authorization = ''; }

  datadogRum.addError(error, { request: error.request, response: error.response, url: error.config.url });

  const isInvalidSession = error?.response?.data?.invalidSession || false;
  const friendlyErrorMessage = error?.response?.data?.friendlyMessage;

  if (error?.name === 'The user is not authenticated'
   || error === 'The user is not authenticated') {
    throw new AuthError(friendlyErrorMessage ?? 'Authentication error. Please login again to continue.');
  }

  if (isInvalidSession) {
    try {
      await AuthService.handleInvalidSession();
    } catch (e) {
      throw new AuthError(friendlyErrorMessage ?? error?.message);
    }
  }

  if (error.message === 'Network Error') {
    throw new NetworkError(friendlyErrorMessage ?? 'Network Error. Please check your connection.');
  }

  throw error;
});

export default instance;
