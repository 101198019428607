import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(({ typography }) => ({
  tradeContainer: {
    backgroundColor: '#F4F4F4',
    padding: '24px',
    borderRadius: '10px',
  },
  detailContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '5px',
  },
  transfersTitle: {
    margin: '0px 35px 0px 25px',
  },
  textStyle: {
    fontSize: 13,
    fontWeight: 400,
    lineHeigt: '15px',
    fontFamily: typography.fontFamilySecondary,
  },
  subHeading: {
    fontFamily: typography.fontFamilySecondary,
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: 600,
    marginBottom: '16px',
  },
  remitContainer: {
    padding: '16px 24px 11px 24px',
    border: '1px solid #EAEAEA',
    borderRadius: '10px',
  },
  buttonContainer: {
    padding: '46px 0px 12px 0px',
    marginTop: 'auto',
  },
  wireTransferGuide: {
    fontWeight: 300,
    fontSize: '12px',
    color: '#212529',
    lineHeight: '19.5px',
    padding: '12px',
  },
  wireTransferFirstParty: {
    fontWeight: 300,
    fontSize: '12px',
    color: '#212529',
    lineHeight: '19.5px',
    padding: '8px',
    marginTop: '40px',
    marginBottom: '290px',
  },
  accountDetailTable: {
    background: 'transparent',
    marginTop: '12px',
    '& .MuiTableBody-root tr': {
      borderBottom: 'none',
    },
    '& .MuiTableBody-root tr td': {
      padding: '5px 0px !important',
    },
  },
  wireDetailsHeader: {
    marginTop: '15px',
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  radioSubtitle: {
    opacity: '50%',
    fontFamily: typography.fontFamilySecondary,
    fontSize: 12,
    padding: '0px 0px 24px 28px',
  },
  divider: {
    padding: '0px 0px 24px 0px',
  },
  transferDivider: {
    padding: '24px 0px 24px 0px',
  },
  linkedPayments: {
    fontWeight: 1000,
    fontSize: 14,
    color: 'Black',
    marginBottom: '40px',
  },
}), { name: 'ConfirmTrade' });

export default useStyles;
