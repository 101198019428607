import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import t from 'utils/translationHelper';

import { AccountDto } from '@alpha/auth-dtos';
import { Box, Typography } from '@alpha/ui-lib/ui/external';

import useAuth from '../../../hooks/useAuth';
import useProfile from '../../../hooks/useProfile';
import useSwitchAccount from '../../../hooks/useSwitchAccount';
import routes from '../../../routes.path';
import history from '../../../services/history/browserHistory';
import { TStore } from '../../../store';
import useStyles from '../Auth.styles';

interface IProps {
  name: string,
}

const Welcome: FC<IProps> = (props: IProps) => {
  const { name } = props;

  const classes = useStyles();
  const redirectPath = useSelector<TStore, string | ''>((store) => store.initialPath);
  const {
    mapPageToPath,
    getPortalPagesConfiguration,
    currentAccount,
    setUserAccount,
    isValidAccountId,
    getUserAccounts,
    updateCurrentAccount,
  } = useSwitchAccount();

  const { userProfile, updateUserProfile } = useProfile();

  const {
    checkAuth, loggedIn, userInfo, handleSignOut,
  } = useAuth();
  const [accounts, setAccounts] = useState<AccountDto[]>();
  const [loadedUserAccountDetails, setLoadedUserAccountDetails] = useState(false);

  const generateUuid = () => uuidv1();

  const setUserAccountDetails = async (): Promise<void> => {
    try {
      await checkAuth();
      if (loggedIn && userInfo) {
        if (!currentAccount || !isValidAccountId) await setUserAccount();
        if (!userProfile) await updateUserProfile();
      }
    } catch {
      handleSignOut();
    }
  };

  const getParameterByName = (path: string, queryName: string): string | null => {
    const match = RegExp(`[?&]${queryName}=([^&]*)`).exec(path);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  };

  const getAllAccounts = async (): Promise<void> => {
    try {
      setAccounts(await getUserAccounts());
    } catch {
      setAccounts([]);
    }
  };

  const redirect = (path: string, timeout = 0): ReturnType<typeof setTimeout> => {
    const timer = setTimeout(() => {
      history.push(path);
    }, timeout);

    return timer;
  };

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>;
    try {
      if (!redirectPath || redirectPath === '/') {
        const landingPage = mapPageToPath('DASHBOARD');
        timerId = redirect(landingPage, 1000);
      } else {
        timerId = redirect(redirectPath, 1000);
      }
    } catch (e) {
      timerId = redirect(mapPageToPath('DASHBOARD'), 1000);
    }

    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [loadedUserAccountDetails]);

  useEffect(() => {
    if (loggedIn && accounts) {
      if (accounts.length > 0) {
        (async () => {
          await setUserAccountDetails();
          await getPortalPagesConfiguration();

          const targetAccountId = getParameterByName(redirectPath, 'accountId');
          if (targetAccountId) {
            const matchedAccount = accounts?.find((account) => account.id === targetAccountId);
            if (matchedAccount) updateCurrentAccount(matchedAccount);
          }

          setLoadedUserAccountDetails(true);
        })();
      }
    }
  }, [loggedIn, accounts]);

  useEffect(() => {
    checkAuth();
    getAllAccounts();
  }, []);

  return (
    <Box className={classes.headerContainer}>
      <Typography className={classes.header}>
        {`${t('welcome_back~')}, ${name}`}

      </Typography>
      <Typography className={classes.subHeader}>
        {t('thank_you_for_verifying_logging_you_in_now')}
      </Typography>
      <Typography className={classes.subHeader2}>
        {t('you_should_be_redirected_shortly_if_not_click_here1')}
        {' '}
        <NavLink to={routes.dashboard}>{t('you_should_be_redirected_shortly_if_not_click_here2')}</NavLink>
        .
      </Typography>
    </Box>
  );
};

export default Welcome;
