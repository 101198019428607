import React from 'react';
import clsx from 'clsx';
import InverseFxRateTooltip from 'components/Molecules/InverseFxRateTooltip/InverseFxRateTooltip';
import { t } from 'i18next';

import { Box } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useStyles } from './Rate.styles';

export enum rateType {
  INDICATIVE = 'Indicative',
  LIVE = 'Live Rate',
  EXPIRED = 'Expired'
}

interface IProps {
  loading?: boolean;
  type: 'Live Rate' | 'Indicative' | 'Expired';
  rate?: number;
  inverseRate?: number;
  debitingCurrency: string | undefined;
  creditingCurrency: string | undefined;
  fixedDecimals?: number;
  mfaVerified?: boolean;
}

const Rate: React.FC<IProps> = (props: IProps) => {
  const {
    type, rate, inverseRate, debitingCurrency, creditingCurrency, loading,
    fixedDecimals = 4, mfaVerified,
  } = props;

  const styles = useStyles({ loading, type, mfaVerified });

  if (!debitingCurrency || !creditingCurrency) return null;

  const handleRateStyling = () => {
    if (type === rateType.LIVE) return styles.live;
    if (type === rateType.EXPIRED) return styles.expired;
    return styles.indicative;
  };

  return (
    <Box className={clsx(styles.root, handleRateStyling())}>
      <InverseFxRateTooltip
        text={(
          <div
            className={clsx(
              styles.rateFont,
              handleRateStyling(),
            )}
          >
            {!loading ? `${t(type)}` : null}
            {!loading ? <FontAwesomeIcon className={styles.icon} icon={faInfoCircle as IconProp} /> : null}
          </div>
        )}
      />
      <Box className={clsx(styles.rate, handleRateStyling())}>
        <Box className={clsx(styles.amount, handleRateStyling())}>
          <Typography
            className={clsx(
              styles.bold,
              type === rateType.LIVE
              && styles.liveRate,
              handleRateStyling(),
            )}
          >
            {
              (loading)
                ? (
                  <Loader
                    testId="loader"
                    size="20px"
                    style={{
                      color: '#3E8CC1', width: '13px', height: '13px', position: 'absolute',
                    }}
                  />
                )
                : rate?.toFixed(fixedDecimals)
            }
          </Typography>
        </Box>
      </Box>
      {
        rate !== undefined && !loading
        && (
          <Box className={styles.inverseRateWrapper}>
            <Typography className={clsx(styles.inverseRate, handleRateStyling())}>
              {
                (loading)
                  ? (
                    <Loader
                      testId="loader"
                      size="20px"
                      style={{
                        color: '#3E8CC1', width: '13px', height: '13px', position: 'absolute',
                      }}
                    />
                  )
                  : inverseRate?.toFixed(fixedDecimals)
              }
            </Typography>
          </Box>
        )
      }
    </Box>
  );
};

export default Rate;
