import React, {
  FC, memo, useMemo, useState,
} from 'react';
import clsx from 'clsx';
import useAuth from 'hooks/useAuth';
import useLog from 'hooks/useLog';
import useSwitchAccount from 'hooks/useSwitchAccount';
import useToggle from 'hooks/useToggle';
import i18n from 'i18next';
import { TUser } from 'models/user';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import routes from 'routes.path';
import browserHistory from 'services/history/browserHistory';
import { TStore } from 'store';
import { Userpilot } from 'userpilot';
import t from 'utils/translationHelper';

import { IMenuItem } from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { PortalHeaderLight } from '@alpha/ui-lib/ui/layout/Header/PortalHeader';
import { faDoorOpen, faExchange, faGears } from '@fortawesome/pro-regular-svg-icons';

import SwitchAccountDrawer from './SwitchAccountDrawer/SwitchAccountDrawer';
import { useStyles } from './Header.styles';

const Header: FC = memo(() => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const { currentAccount } = useSwitchAccount();
  const { open } = useToggle();
  const { logEvent } = useLog();
  const [showSwitchAccountDrawer, setShowSwitchAccountDrawer] = useState<boolean>(false);

  const classes = useStyles();
  const location = useLocation();

  const user = useSelector<TStore, TUser | undefined>((state: TStore) => state.user.user);
  const data = useMemo(() => ({
    account: user?.Account?.name ?? '',
    username: user?.Username,
    permissions: [],
    main: false,
  }), [user]);

  const handleOpenSwitchAccountDrawer = (): void | undefined => setShowSwitchAccountDrawer(true);

  const handleOpenSettingsPage = (): void => browserHistory.push(routes.accountSettings);

  const dropDownItems = useMemo(
    () => [
      {
        content: <div>{t('switch_entity')}</div>,
        icon: faExchange,
        arrow: true,
        key: 'Switch account',
        onClick: () => {
          logEvent({ action: 'Open Switch Account Drawer' });
          handleOpenSwitchAccountDrawer();
        },
      },
      {
        content: <div>{t('entity_settings')}</div>,
        icon: faGears,
        arrow: false,
        key: 'Account Settings',
        onClick: () => {
          logEvent({ action: 'Open Settings Page' });
          handleOpenSettingsPage();
        },
      },
      {
        title: <div>Logout</div>,
        content: <div>{t('logout')}</div>,
        icon: faDoorOpen,
        key: 'Logout',
        onClick: () => {
          logEvent({ action: 'Click Logout' });
          Userpilot.destroy();
          auth.handleSignOut();
        },
      },
    ],
    [open, dispatch, data],
  );
  let flagAttribute = {};

  flagAttribute = {
    languageInfo: {
      country: i18n.language.toUpperCase(),
      currencyCode: (i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1)) === 'En' ? 'GBP'
        : (i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1)),
    },
  };

  const dashboardClassname = location.pathname.includes('dashboard') ? classes.dashboardHeaderContainer : '';

  let contactNumber = '+44 (0)203 800 0120';

  if (i18n.language === 'it' || i18n.language === 'fr') {
    contactNumber = '+39 (0)28 2712 779';
  }

  return (
    <div className={clsx(dashboardClassname)}>
      <PortalHeaderLight
        menuTitle="menu"
        testId="portal-header"
        highlighted={data.main}
        dropDownItems={dropDownItems as IMenuItem[]}
        dropDownTestId="portalHeader-dropdown"
        menuButtonTestId="portalHeader-menu-button"
        userTitle={auth.userInfo?.Name ? auth.userInfo.Name : ''}
        userAccount={currentAccount.name}
        showContactInfo
        contactInfo={{
          email: 'clientservices-rm@alphafx.co.uk',
          phone: contactNumber,
        }}
        {...flagAttribute}
        contactStrings={{ needHelp: t('need_help_get_in_touch'), clientServices: t('client_services'), contactUs: t('contact_us') }}
        handleOpenLanguages={() => {
          logEvent({ action: 'Open Settings Page' });
          handleOpenSettingsPage();
        }}
      />
      <SwitchAccountDrawer
        open={showSwitchAccountDrawer}
        onClose={setShowSwitchAccountDrawer}
      />
    </div>
  );
});

export default Header;
