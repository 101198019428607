/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import i18n from 'i18n/config';
import { useParams } from 'react-router';
import t from 'utils/translationHelper';

import { CurrencyAccountDto, CurrencyAccountTransferDto } from '@alpha/currency-accounts-dtos';
import { ExecuteTradeResultDto, TradeFundingMethod } from '@alpha/fx-dtos';
import { PaymentDto } from '@alpha/payments-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Divider, Typography } from '@alpha/ui-lib/ui/external';
import { RadioButton } from '@alpha/ui-lib/ui/RadioButton';
import { StyledDivider } from '@alpha/ui-lib/ui/StyledDivider';
import { StyledKeyValueTable, TKeyValue } from '@alpha/ui-lib/ui/table';

import AlphaLogo from '../../../../../assets/alphaGreenLogo.svg';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../../hooks/useLog';
import FXTradeService from '../../../../../services/FXTrade/fxTrade.service';
import { formatCurrency, formatNumber } from '../../../../../utils/currency.helpers';
import formatIsoDate from '../../../../../utils/formatIsoDate';
import mapTradeFundingMethodToFriendlyName from '../../../../../utils/fxTrades/mapTradeFundingMethodToFriendlyName';
import Alert from '../../../../Alert';
import LinkedPaymentsSummary from '../../../Payments/Summary/LinkedPayments';
import { TSpotFXRequestForm } from '../formData';
import { TSpotDrawState } from '..';

import useStyles from './index.styles';
import TransferConfirmDetails from './TransferConfirmDetails';

interface IProps {
  form: FormikProps<TSpotFXRequestForm>
  handleClose: () => void
  setDrawerState: React.Dispatch<React.SetStateAction<TSpotDrawState>>;
  bookedTrade?: ExecuteTradeResultDto
  bookedTransfer?: CurrencyAccountTransferDto
  firstPartyFlow: boolean
  paymentsAllowed: boolean
  setTradeBooked?: React.Dispatch<React.SetStateAction<boolean>>;
}

// eslint-disable-next-line max-lines-per-function
const ConfirmTrade: React.FC<IProps> = (props) => {
  const {
    form,
    handleClose,
    setDrawerState,
    bookedTrade,
    paymentsAllowed,
    bookedTransfer,
    firstPartyFlow,
    setTradeBooked,
  } = props;

  const styles = useStyles();
  const { logError } = useLog();
  const [remitPayment, setRemitPayment] = useState<boolean>(paymentsAllowed);
  const [accountDetailData, setAccountDetailData] = useState<TKeyValue[]>([]);
  const [tradePayments, setTradePayments] = useState<PaymentDto[]>([]);
  const [showLinkedPayments, setShowLinkedPayments] = useState(false);
  const sb = useAlphaSnackbar();
  const { tradeId } = useParams<{
    tradeId: string;
  }>();

  const updateAccountDetail = (currencyAccount: CurrencyAccountDto) => {
    const currentAccountDetailData = [];
    if (currencyAccount.accountNumber) { currentAccountDetailData.push({ key: t('account_number'), value: currencyAccount.accountNumber || '-', boldValue: true }); }
    if (currencyAccount.sortCode) { currentAccountDetailData.push({ key: t('national_bank_code'), value: currencyAccount.sortCode || '-', boldValue: true }); }
    if (currencyAccount.iban) { currentAccountDetailData.push({ key: t('iban'), value: currencyAccount.iban || '-', boldValue: true }); }
    if (currencyAccount.swift) { currentAccountDetailData.push({ key: 'SWIFT', value: currencyAccount.swift || '-', boldValue: true }); }
    if (currencyAccount.reference) { currentAccountDetailData.push({ key: t('reference'), value: currencyAccount.reference || '-', boldValue: true }); }
    setAccountDetailData(currentAccountDetailData);
  };

  const loadTradePayments = async (queryTradeId: string) => {
    try {
      const paymentsData: PaymentDto[] = await FXTradeService.getTradePaymentsData(queryTradeId);
      setTradePayments(paymentsData);
    } catch (error) {
      sb.trigger(error?.message || t('failed_to_load_payments'));
      logError({ action: 'Failed loading trade payments', error });
    }
  };

  useEffect(() => {
    if (form.values.defaultSellCurrencyAccount) {
      updateAccountDetail(form.values.defaultSellCurrencyAccount);
    }
  }, []);

  useEffect(() => {
    if (bookedTrade && tradeId) {
      loadTradePayments(bookedTrade?.tradeId);
      setShowLinkedPayments(true);
      if (setTradeBooked) { setTradeBooked(true); }
    }
    if (bookedTrade) {
      loadTradePayments(bookedTrade?.tradeId);
      setShowLinkedPayments(true);
    }
  }, [bookedTrade]);

  return (
    <>
      <div style={{
        display: 'flex', flexDirection: 'column', position: 'relative', padding: '12px',
      }}
      >
        <div className={styles.tradeContainer}>
          <div className={styles.detailContainer}>
            <Typography className={styles.textStyle}>{t('sell_currency')}</Typography>
            <Typography className={styles.textStyle}>
              {`${bookedTrade?.sellCurrency}` || '-'}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: '#EAEAEA', margin: '8px 0px 8px 0px' }} />

          <div className={styles.detailContainer}>
            <Typography className={styles.textStyle}>{t('sell_amount')}</Typography>
            <Typography className={styles.textStyle}>
              {formatCurrency(bookedTrade?.sellCurrency, bookedTrade?.sellAmount) || '-'}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: '#EAEAEA', margin: '8px 0px 8px 0px' }} />

          <div className={styles.detailContainer}>
            <Typography className={styles.textStyle}>{t('rate')}</Typography>
            {bookedTrade?.rate && (
              <Typography className={styles.textStyle}>
                {formatNumber(bookedTrade?.rate || 0, 4) || '-'}
                &#124;
                {formatNumber(1 / bookedTrade?.rate, 4) || '0'}
              </Typography>
            )}
          </div>
          <Divider style={{ backgroundColor: '#EAEAEA', margin: '8px 0px 8px 0px' }} />

          <div className={styles.detailContainer}>
            <Typography className={styles.textStyle}>{t('buy_currency')}</Typography>
            <Typography className={styles.textStyle}>
              {`${bookedTrade?.buyCurrency}` || '-'}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: '#EAEAEA', margin: '8px 0px 8px 0px' }} />

          <div className={styles.detailContainer}>
            <Typography className={styles.textStyle}>{t('buy_amount')}</Typography>
            <Typography className={styles.textStyle}>
              {formatCurrency(bookedTrade?.buyCurrency, bookedTrade?.buyAmount) || '-'}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: '#EAEAEA', margin: '8px 0px 8px 0px' }} />

          <div className={styles.detailContainer}>
            <Typography className={styles.textStyle}>{t('value_date')}</Typography>
            <Typography className={styles.textStyle}>
              {formatIsoDate(bookedTrade?.valueDate) || '-'}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: '#EAEAEA', margin: '8px 0px 8px 0px' }} />

          {!bookedTransfer && (
            <div className={styles.detailContainer}>
              <Typography className={styles.textStyle}>{t('funding_method')}</Typography>
              <Typography className={styles.textStyle}>{t(mapTradeFundingMethodToFriendlyName(form.values.fundingMethod)) || '-'}</Typography>
            </div>
          )}
        </div>
      </div>
      {form.values.fundingMethod === TradeFundingMethod.WIRE_TRANSFER && (
        <div className={styles.wireTransferGuide}>
          <Typography style={{ fontWeight: 600, fontSize: '16px', lineHeight: '21px' }}>{t('wire_transfer_details')}</Typography>
          <span>
            {t('please_ensure_funds_are_remitted_to_alpha_bank')}
          </span>

          <Typography className={styles.wireDetailsHeader}>
            <img
              alt="Alpha Account"
              src={AlphaLogo}
              style={{ verticalAlign: 'top', marginRight: '8px' }}
            />
            Alpha Group
            <div className={styles.accountDetailTable}>
              <StyledKeyValueTable
                testId="account-detail-table"
                data={accountDetailData}
              />
            </div>
          </Typography>
        </div>
      )}
      {paymentsAllowed && !bookedTransfer && !firstPartyFlow && (
        <div style={{
          display: 'flex', flexDirection: 'column', position: 'relative', padding: '12px',
        }}
        >
          <div className={styles.remitContainer}>
            <Typography className={styles.subHeading}>
              {t('would_you_like_to_send_payments_from_this_trade')}
            </Typography>
            <div className={styles.radioContainer}>
              <RadioButton
                testId="spot-remit-payment"
                checked={remitPayment}
                onChange={() => {
                  setRemitPayment(true);
                }}
                label={(
                  <p>
                    <strong>{t('yes')}</strong>
                    {' '}
                    |
                    {' '}
                    <span style={{ color: '#00000080' }}>{t('i_want_to_create_payments_to_have_funds_sent_onwards_by_alpha_fx')}</span>
                  </p>
              )}
              />

              <RadioButton
                testId="spot-remit-payment"
                checked={!remitPayment}
                onChange={() => {
                  setRemitPayment(false);
                }}
                label={(

                  <p>
                    <strong>{t('no')}</strong>
                    {' '}
                    |
                    {' '}
                    <span style={{ color: '#00000080' }}>
                      {i18n.language === 'es' ? t('curreny_will_be_added_to_currency_account')
                        : `${t('the')} ${form?.values.buyCurrencyCode} ${t('bought_will_be_added_to_your_currency_account')} 
                      (${form.values.defaultBuyCurrencyAccount?.friendlyName || form.values.defaultBuyCurrencyAccount?.accountName}) 
                      ${t('held_by_alpha_fx')}`}
                    </span>
                  </p>
              )}
              />
              {!remitPayment && (
              <Alert
                variant="info"
                text={`${form?.values.buyCurrencyCode} ${formatNumber(bookedTrade?.buyAmount || 0, 2)} 
                ${t('will_be_available_in_your_currency_account')} (${form.values.defaultBuyCurrencyAccount?.friendlyName
                  || form.values.defaultBuyCurrencyAccount?.accountName}) ${t('held_with_alpha_fx')}`}
              />
              )}
            </div>
          </div>
        </div>
      )}
      {showLinkedPayments && !paymentsAllowed && !bookedTransfer && tradePayments.length ? (
        <div className={styles.remitContainer}>
          <Typography className={
            styles.linkedPayments
          }
          >
            {t('linked_payments')}
          </Typography>
          <div style={{ marginTop: '24px' }}>
            <LinkedPaymentsSummary values={tradePayments} />
          </div>
        </div>
      ) : null}
      {bookedTransfer
        ? <TransferConfirmDetails bookedTransfer={bookedTransfer} />
        : null}
      <div className={styles.buttonContainer}>
        {remitPayment && !firstPartyFlow
          ? <ActionButton onClick={() => setDrawerState('TradePayments')} style={{ width: '100%' }}>{t('proceed_to_payments')}</ActionButton>
          : <ActionButton onClick={handleClose} style={{ width: '100%' }}>{t('exit')}</ActionButton>}
      </div>
    </>
  );
};

export default ConfirmTrade;
