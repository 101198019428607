import React, { useLayoutEffect } from 'react';
import DefaultErrorBoundary from 'components/ErrorBoundary/DefaultErrorBoundary';
import BatchBeneficiariesSummary from 'domain/Beneficiaries/BatchBeneficiariesSummary/BatchBeneficiariesSummary';
import CreditFacility from 'domain/CreditFacility';
import FacilityTerm from 'domain/FacilityTerm';
import Trade from 'domain/Transactions/Dashboard/OptionTradesTable/OptionTrade/Trade';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';
import { withErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import {
  Redirect, Route, Router, Switch,
} from 'react-router-dom';

import { CssBaseline } from '@alpha/ui-lib/ui/external';
import { SnackbarProvider } from '@alpha/ui-lib/ui/Snackbar';

import { Page } from './components/Layout/Page';
import { Notifier } from './components/Notifier';
import { NavSidebarContext } from './context/NavSidebarContext';
import AccountSettings from './domain/AccountSettings';
import Auth from './domain/Auth';
import Authy from './domain/Authy/Authy';
import { Beneficiaries } from './domain/Beneficiaries';
import { CurrencyAccounts } from './domain/CurrencyAccounts';
import Dashboard from './domain/Dashboard';
import HedgingPrograms from './domain/HedgingPrograms';
import InactivityTracker from './domain/InactivityTracker/InactivityTracker';
import MarketInformation from './domain/MarketInformation/MarketInformation';
import MarketOrders from './domain/MarketOrders/MarketOrders';
import { Statements } from './domain/Statements';
import TradeDetail from './domain/Transactions/Dashboard/FxTradeTable/TradeDetail/TradeDetail';
import PaymentBatchDetail from './domain/Transactions/Dashboard/PaymentTableContainer/PaymentBatchDetail/PaymentBatchDetail';
import PaymentFileUpload from './domain/Transactions/PaymentFileUpload';
import UploadSummary from './domain/Transactions/PaymentFileUpload/UploadSummary/UploadSummary';
import Transactions from './domain/Transactions/Transactions';
import PrivateRoute from './routes/PrivateRoute';
import history from './services/history/browserHistory';
import { actions } from './store/initialPath/reducer';
import useStyles from './App.styles';
import routes from './routes.path';

const App: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { search, pathname } = history.location;
  // This is used to redirect user to previous route if they wasn't logged in
  useLayoutEffect(() => {
    if (!pathname.includes(routes.auth.base)) {
      dispatch(actions.updateInitialPath(`${pathname}${search}`));
    }
  }, []);

  return (
    <SnackbarProvider
      maxSnack={6}
      classes={{ variantSuccess: classes.success, variantInfo: classes.info, variantError: classes.error }}
    >
      <CssBaseline />
      <Router history={history}>
        <Switch>
          <Route exact path={routes.base}>
            <Redirect to={routes.dashboard} />
          </Route>
          <PrivateRoute path={routes.home}>
            <Authy />
            <InactivityTracker>
              <NavSidebarContext>
                <Page>
                  <Route
                    exact
                    path={routes.currencyAccounts}
                    component={CurrencyAccounts}
                  />
                  <Route exact path={routes.transactions.optionTrade} component={Trade} />
                  <Route
                    exact
                    path={[
                      routes.transactions.base,
                      routes.transactions.fxtrades,
                      routes.transactions.transfer,
                    ]}
                  >
                    <Transactions />
                  </Route>
                  <Route path={routes.transactions.optionTrades}>
                    <Transactions />
                  </Route>
                  <Route exact path={routes.beneficiaries} component={Beneficiaries}>
                    <Beneficiaries />
                  </Route>
                  <Route
                    path={routes.batchBeneficiariesSummary}
                    component={BatchBeneficiariesSummary}
                  />
                  <Route
                    path={routes.noStatements}
                    component={Statements}
                    exact
                  />
                  <Route path={routes.statements} component={Statements} />
                  <Route path={routes.marketOrders} component={MarketOrders} />
                  <Route path={routes.marketInformation} component={MarketInformation} />
                  <Route path={routes.hedgingPrograms} component={HedgingPrograms} />
                  <Route path={routes.transactions.emptyTrades} exact>
                    <Redirect to={routes.transactions.fxtrades} />
                  </Route>
                  <Route exact path={routes.transactions.trades} component={TradeDetail} />
                  <Route exact path={routes.transactions.payments} component={PaymentBatchDetail} />
                  <Route path={routes.transactions.paymentUpload} component={PaymentFileUpload} />
                  <Route path={routes.transactions.uploadSummary} component={UploadSummary} />
                  <Route path={routes.accountSettings} component={AccountSettings} />
                  <Route path={routes.dashboard} component={Dashboard} />
                  <Route path={routes.creditTerm} component={FacilityTerm} />
                  <Route path={routes.credit} component={CreditFacility} exact />
                </Page>
              </NavSidebarContext>
            </InactivityTracker>
          </PrivateRoute>
          <Auth />
        </Switch>
      </Router>
      <Notifier />
    </SnackbarProvider>
  );
};

export default withErrorBoundary(App, { FallbackComponent: DefaultErrorBoundary });
