import { createRef, useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import moment from 'moment';
import CurrencyAccountsService from 'services/CurrencyAccounts/currencyAccounts.service';
import FXTradeService from 'services/FXTrade/fxTrade.service';
import { getCurrencySymbol } from 'utils/currency.helpers';
import t from 'utils/translationHelper';

import {
  AllowedCurrenciesDto, IndicativeRateResponse,
} from '@alpha/fx-dtos';

import { IOption } from '../../../../AutocompleteDropDown/AutocompleteDropDown';
import { TSpotFXRequestForm } from '../formData';

// eslint-disable-next-line max-lines-per-function
export const useInputSpotTrade = (
  form: FormikProps<TSpotFXRequestForm>,
) => {
  const emptyOption = { name: '', code: '' };
  const sb = useAlphaSnackbar();
  const { logError } = useLog();
  const [
    allowedSellCurrenciesOption,
    setAllowedSellCurrenciesOption,
  ] = useState<IOption[]>([]);

  const [
    allowedBuyCurrenciesOption,
    setAllowedBuyCurrenciesOption,
  ] = useState<IOption[]>([]);

  const [
    selectedSellCurrencyOption,
    setSelectedSellCurrencyOption,
  ] = useState<IOption>(emptyOption);

  const [
    selectedBuyCurrencyOption,
    setSelectedBuyCurrencyOption,
  ] = useState<IOption>(emptyOption);

  const [fixedSide, setFixedSide] = useState<'Sell' | 'Buy'>(form.values.fixedCurrencyCode === form.values.buyCurrencyCode ? 'Buy' : 'Sell');
  const [sellCurrencyTextFieldValue, setSellCurrencyTextFieldValue] = useState<string>('');
  const [buyCurrencyTextFieldValue, setBuyCurrencyTextFieldValue] = useState<string>('');
  const [indicativeRate, setIndicativeRate] = useState<IndicativeRateResponse>();
  const [indicativeRateReceived, setIndicativeRateReceived] = useState<boolean>(false);
  const [availableSpotDates, setAvailableSpotDates] = useState<string[]>([]);
  const [isCurrencySwap, setIsCurrencySwap] = useState<boolean>(false);
  const [requestedDate, setRequestedDate] = useState<string>();
  const [excludedDates, setExcludedDates] = useState<string[]>([]);
  const [dateAdjusted, setDateAdjusted] = useState<boolean>(false);
  const [initialDate, setInitialDate] = useState<boolean>(false);
  const [action, setAction] = useState<string>('');

  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [rateLoading, setRateLoading] = useState<boolean>(false);
  const [currencySwitchRateLoading, setCurrencySwitchRateLoading] = useState<boolean>(false);
  const [valueDatesLoading, setValueDatesLoading] = useState<boolean>(false);

  const [buyStartAdornment, setBuyStartAdornment] = useState<string>('');
  const [sellStartAdornment, setSellStartAdornment] = useState<string>('');

  const sellAmountInputRef = createRef<HTMLInputElement>();

  const getAvailableCurriencies = async (): Promise<void> => {
    setPageLoading(true);
    try {
      const allowCurrencies: AllowedCurrenciesDto = await FXTradeService.getAllowedFXCurrencies();
      setAllowedBuyCurrenciesOption(allowCurrencies.buyAllowed);
      setAllowedSellCurrenciesOption(allowCurrencies.sellAllowed);

      setPageLoading(false);
    } catch (error) {
      sb.trigger(error?.message || 'Failed to load available currencies.', 'error');
      logError({ action: 'Error loading available currencies', error });
    }
  };

  const manualResetForm = () => {
    form.setFieldValue('sellAmount', 0);
    form.setFieldValue('buyAmount', 0);
    form.setFieldValue('valueDate', null);
    form.setFieldValue('fixedAmount', 0);
    form.setFieldValue('defaultSellCurrencyAccount', {});
    form.setFieldValue('defaultBuyCurrencyAccount', {});
    form.setFieldValue('selectedSellCurrencyAccount', {});
    form.setFieldValue('selectedBuyCurrencyAccount', {});
    form.setFieldValue('sellCurrencyCode', '');
    form.setFieldValue('buyCurrencyCode', '');
    setSelectedBuyCurrencyOption({ name: '', code: '' });
    setSelectedSellCurrencyOption({ name: '', code: '' });
    setSellCurrencyTextFieldValue('');
    setBuyCurrencyTextFieldValue('');
    setDateAdjusted(false);
    setIndicativeRate(undefined);
    form.setFieldTouched('sellAmount', false, false);
    form.setFieldTouched('buyAmount', false, false);
  };

  const updateCounterSideAmount = async (
    amountFixedSide: 'Buy' | 'Sell',
    amount: number,
    rate: number,
    buyCcy: string,
    sellCcy: string,
  ): Promise<void> => {
    if (!(buyCcy && sellCcy)) {
      return;
    }

    const opposingFieldAmount = FXTradeService.calculateTradeAmount(
      amountFixedSide,
      amount,
      rate || 0,
    );

    if (amountFixedSide === 'Buy') {
      (async () => {
        await form.setFieldValue('sellAmount', opposingFieldAmount.toFixed(2), true);
        await form.setFieldTouched('sellAmount', false, false);
        form.validateField('sellAmount');
      })();
    } else {
      (async () => {
        await form.setFieldValue('buyAmount', opposingFieldAmount.toFixed(2), true);
        await form.setFieldTouched('buyAmount', false, false);
        form.validateField('buyAmount');
      })();
    }
  };

  const getIndicativeRate = async (
    sellCurrency: string,
    buyCurrency: string,
    fixedSideIndicative: string,
    amount?: number,
    valueDate?: string,
  ): Promise<void> => {
    setRateLoading(true);
    try {
      const indicativeRateResponse = await FXTradeService.getSpotTradeIndicativeRate(
        sellCurrency,
        buyCurrency,
        fixedSideIndicative,
        amount,
        valueDate,
      );
      if (indicativeRateResponse?.valueDate) {
        setIndicativeRate(indicativeRateResponse);
        setIndicativeRateReceived(true);
      }
      form.setFieldValue('indicativeRate', indicativeRateResponse.rate);
      setRateLoading(false);
      setCurrencySwitchRateLoading(false);
    } catch (e: any) {
      setIndicativeRate({ rate: undefined as unknown as number, inverseRate: undefined, valueDate: valueDate as unknown as Date });
      form.setFieldValue('indicativeRate', 0);
      setRateLoading(false);
      setCurrencySwitchRateLoading(false);
      setDateAdjusted(false);
      const errorMessage = (e.response?.data?.errors?.length
        && (t(e.response?.data?.errors[0]))) || t(e.response?.data?.error) || e.message || 'Unable to load indicative rate';
      sb.trigger(errorMessage, 'error');
      logError({ action: 'Error getting indicative rate', error: e });
    }
  };

  const getAvailableSpotDates = async (
    sellCurrencyCode: string,
    buyCurrencyCode: string,
  ): Promise<void> => {
    setValueDatesLoading(true);
    try {
      const availableSpotDatesResponse = await FXTradeService.getAvailableSpotDates(
        sellCurrencyCode,
        buyCurrencyCode,
      );
      setAvailableSpotDates(availableSpotDatesResponse.availableDates);
      setValueDatesLoading(false);
    } catch (e: any) {
      setAvailableSpotDates([]);
      setValueDatesLoading(false);
    }
  };

  const getDefaultCurrencyAccount = async (
    currencyCode: string,
  ) => {
    const currencyAccounts = await CurrencyAccountsService.getCurrencyStats(
      currencyCode,
    );

    const defaultCA = currencyAccounts.currencyAccounts?.find(
      (ca) => ca.default,
    );

    if (defaultCA) {
      return defaultCA;
    }

    return currencyAccounts.currencyAccounts[0];
  };

  const insufficientFunds = (): boolean => {
    if (form.values?.selectedSellCurrencyAccount) {
      return +form.values.sellAmount.toString().replace(/,/g, '') > form.values?.selectedSellCurrencyAccount.clearedBalance;
    }
    return false;
  };

  const handleClearAll = () => {
    form.resetForm();
    setSelectedBuyCurrencyOption(emptyOption);
    setSelectedSellCurrencyOption(emptyOption);
    setDateAdjusted(false);
  };

  useEffect(() => {
    getAvailableCurriencies();
    if (form.values.draftInitiated) {
      setFixedSide(form.values.fixedCurrencyCode === form.values.buyCurrencyCode ? 'Buy' : 'Sell');
    }
  }, []);

  useEffect(() => {
    const preservedSellCurrencyOption = allowedSellCurrenciesOption.find(
      (i) => i.code === form.values.sellCurrencyCode,
    );
    if (preservedSellCurrencyOption) { setSelectedSellCurrencyOption(preservedSellCurrencyOption); }
  }, [allowedSellCurrenciesOption]);

  useEffect(() => {
    const preservedBuyCurrencyOption = allowedBuyCurrenciesOption.find(
      (i) => i.code === form.values.buyCurrencyCode,
    );
    if (preservedBuyCurrencyOption) { setSelectedBuyCurrencyOption(preservedBuyCurrencyOption); }
  }, [allowedBuyCurrenciesOption]);

  useEffect(() => {
    if (
      selectedSellCurrencyOption
      && selectedBuyCurrencyOption
      && form.values.sellCurrencyCode !== ''
      && form.values.buyCurrencyCode !== ''
      && form.values.sellCurrencyCode !== form.values.buyCurrencyCode) {
      if (form.values.draftInitiated && form.values.valueDate) {
        const todayDate = moment().format('YYYY-MM-DD');
        if (moment(form.values.valueDate).isSameOrAfter(todayDate)) {
          getIndicativeRate(form.values.sellCurrencyCode, form.values.buyCurrencyCode, fixedSide, form.values.fixedAmount, form.values.valueDate);
        } else {
          getIndicativeRate(form.values.sellCurrencyCode, form.values.buyCurrencyCode, fixedSide, form.values.fixedAmount, availableSpotDates[0]);
        }
      } else {
        form.setFieldValue('buyAmount', 0, false);
        form.setFieldTouched('buyAmount', false);
        form.setFieldValue('sellAmount', 0, false);
        form.setFieldTouched('sellAmount', false);
        form.setFieldValue('fixedAmount', 0, false);
        form.setFieldTouched('fixedAmount', false);
        setIndicativeRate(undefined);
      }

      // only reset available dates upon new currency selection, not currency swap
      if (isCurrencySwap) {
        setIsCurrencySwap(false);
      } else {
        setDateAdjusted(false);
        setInitialDate(true);
        setExcludedDates([]);
        getAvailableSpotDates(form.values.sellCurrencyCode, form.values.buyCurrencyCode);
        sellAmountInputRef.current?.focus();
      }
    }
  }, [form.values.sellCurrencyCode, form.values.buyCurrencyCode]);

  // gets spot dates on initial selection of currency pair
  useEffect(() => {
    if (availableSpotDates.length
      && !excludedDates.length
      && !form.values.draftInitiated
      && !currencySwitchRateLoading) {
      form.setFieldValue('valueDate', availableSpotDates[0]);
    }
  }, [availableSpotDates]);

  // handles value date adjustment
  useEffect(() => {
    if (indicativeRateReceived && indicativeRate?.valueDate && moment(indicativeRate.valueDate).diff(requestedDate)) {
      setAvailableSpotDates([...availableSpotDates, moment(indicativeRate?.valueDate).format('YYYY-MM-DD')]);
      if (!initialDate) {
        setDateAdjusted(true);
      }
      setExcludedDates([...excludedDates, requestedDate as string]);
      form.setFieldValue('valueDate', moment(indicativeRate?.valueDate).format('YYYY-MM-DD'));
    } else if (indicativeRateReceived && indicativeRate?.valueDate && moment(indicativeRate.valueDate).isSame(requestedDate)) {
      setDateAdjusted(false);
    }
    setIndicativeRateReceived(false);
  }, [indicativeRateReceived]);

  // disables all dates within the excludedDates state from the datepicker
  useEffect(() => {
    if (excludedDates.length) {
      setAvailableSpotDates(availableSpotDates.filter((date) => !excludedDates.includes(date)));
    }
  }, [excludedDates]);

  // handles indicative rate call upon value date adjustment
  useEffect(() => {
    setRequestedDate(form.values.valueDate);
    if ((!indicativeRate?.valueDate
      && selectedSellCurrencyOption?.code !== ''
      && selectedBuyCurrencyOption?.code !== ''
      && form.values.fixedAmount !== 0)
      || (indicativeRate?.valueDate && moment(indicativeRate.valueDate).diff(form.values.valueDate))) {
      getIndicativeRate(selectedSellCurrencyOption.code, selectedBuyCurrencyOption.code, fixedSide, form.values.fixedAmount, form.values.valueDate);
    }
  }, [form.values.valueDate]);

  useEffect(() => {
    setSellStartAdornment(getCurrencySymbol(form.values.sellCurrencyCode) || '');
    form.setFieldValue('sellAmount', form.values.sellAmount, false);
    form.setFieldValue('buyAmount', form.values.buyAmount, false);

    if (form.values.fixedCurrencyCode && form.values.fixedAmount > 0) {
      const buySideFixed = form.values.fixedCurrencyCode === form.values.buyCurrencyCode;
      form.setFieldTouched('sellAmount', !buySideFixed);
      form.setFieldTouched('buyAmount', buySideFixed);
    }

    if (fixedSide !== 'Sell') {
      // Clear sell amounts
      form.setFieldValue('sellAmount', 0, false);
      form.setFieldTouched('sellAmount', false);
    }

    if (fixedSide === 'Sell') {
      form.setFieldValue('fixedCurrencyCode', form.values.sellCurrencyCode);
      const sellAmount = parseFloat(form.values.sellAmount.toString().replace(/,/g, ''));
      form.setFieldValue('fixedAmount', sellAmount);
    }
    (async () => {
      if (form.values.sellCurrencyCode) {
        const defaultSellCurrencyAccount = await getDefaultCurrencyAccount(
          form.values.sellCurrencyCode,
        );
        form.setFieldValue('defaultSellCurrencyAccount', defaultSellCurrencyAccount);
      } else {
        form.setFieldValue('defaultSellCurrencyAccount', undefined);
      }
    })();
  }, [form.values.sellCurrencyCode]);

  useEffect(() => {
    setBuyStartAdornment(getCurrencySymbol(form.values.buyCurrencyCode) || '');
    form.setFieldValue('sellAmount', form.values.sellAmount, false);
    form.setFieldValue('buyAmount', form.values.buyAmount, false);

    if (form.values.fixedCurrencyCode && form.values.fixedAmount > 0) {
      const buySideFixed = form.values.fixedCurrencyCode === form.values.buyCurrencyCode;
      form.setFieldTouched('sellAmount', !buySideFixed);
      form.setFieldTouched('buyAmount', buySideFixed);
    }

    if (fixedSide !== 'Buy') {
      // Clear buy amount
      form.setFieldValue('buyAmount', 0, false);
      form.setFieldTouched('buyAmount', false);
    }

    if (fixedSide === 'Buy') {
      form.setFieldValue('fixedCurrencyCode', form.values.buyCurrencyCode);
      const buyAmount = parseFloat(form.values.buyAmount.toString().replace(/,/g, ''));
      form.setFieldValue('fixedAmount', buyAmount);
    }
    (async () => {
      if (form.values.buyCurrencyCode) {
        const defaultBuyCurrencyAccount = await getDefaultCurrencyAccount(
          form.values.buyCurrencyCode,
        );
        form.setFieldValue('defaultBuyCurrencyAccount', defaultBuyCurrencyAccount);
      } else {
        form.setFieldValue('defaultBuyCurrencyAccount', undefined);
      }
    })();
  }, [form.values.buyCurrencyCode]);

  const swapCurrencies = async () => {
    setIsCurrencySwap(true);

    const newSellCcyCode = form.values.buyCurrencyCode;
    const newBuyCcyCode = form.values.sellCurrencyCode;

    const newSellCurrencyAccount = form.values.selectedBuyCurrencyAccount;
    const newBuyCurrencyAccount = form.values.selectedSellCurrencyAccount;

    const newSellCcy = allowedSellCurrenciesOption.find((item) => (item.code === newSellCcyCode));
    const newBuyCcy = allowedBuyCurrenciesOption.find((item) => (item.code === newBuyCcyCode));

    // If selected, a buy currency can be swapped only if in the sell-allowed list , and vice versa
    const canSwap = (!newSellCcyCode || newSellCcy) && (!newBuyCcyCode || newBuyCcy);

    if (!canSwap) {
      sb.trigger('Note: could not swap these two currencies.', 'info');
      return;
    }

    if (fixedSide === 'Sell') {
      form.setFieldValue('buyAmount', form.values.sellAmount, true);
      form.setFieldValue('sellAmount', 0, true);
    } else {
      form.setFieldValue('sellAmount', form.values.buyAmount, true);
      form.setFieldValue('buyAmount', 0, true);
    }

    setFixedSide(fixedSide === 'Sell' ? 'Buy' : 'Sell');

    setSelectedBuyCurrencyOption(newBuyCcy ?? { name: '', code: '' });
    setSelectedSellCurrencyOption(newSellCcy ?? { name: '', code: '' });

    form.setFieldValue('buyCurrencyCode', newBuyCcyCode, true);
    form.setFieldValue('sellCurrencyCode', newSellCcyCode, true);

    form.setFieldValue('selectedBuyCurrencyAccount', newBuyCurrencyAccount);
    form.setFieldValue('selectedSellCurrencyAccount', newSellCurrencyAccount);

    setAction('swapCurrencies');
    form.setTouched({
      buyAmount: true,
      sellAmount: true,
      fixedAmount: true,
      buyCurrencyCode: true,
      sellCurrencyCode: true,
    });
    form.validateForm();

    if (form.values.fixedAmount > 0 && !form.values.draftInitiated) {
      getIndicativeRate(newSellCcyCode, newBuyCcyCode, fixedSide === 'Sell' ? 'Buy' : 'Sell',
        form.values.fixedAmount, form.values.valueDate);
      setCurrencySwitchRateLoading(true);
    }
  };

  return {
    allowedSellCurrenciesOption,
    allowedBuyCurrenciesOption,
    selectedSellCurrencyOption,
    setSelectedSellCurrencyOption,
    selectedBuyCurrencyOption,
    setSelectedBuyCurrencyOption,
    sellCurrencyTextFieldValue,
    setSellCurrencyTextFieldValue,
    buyCurrencyTextFieldValue,
    setBuyCurrencyTextFieldValue,
    fixedSide,
    setFixedSide,
    indicativeRate,
    getIndicativeRate,
    pageLoading,
    setPageLoading,
    rateLoading,
    currencySwitchRateLoading,
    updateCounterSideAmount,
    buyStartAdornment,
    setBuyStartAdornment,
    sellStartAdornment,
    setSellStartAdornment,
    insufficientFunds,
    availableSpotDates,
    valueDatesLoading,
    swapCurrencies,
    handleClearAll,
    manualResetForm,
    dateAdjusted,
    setRequestedDate,
    setExcludedDates,
    setInitialDate,
    sellAmountInputRef,
  };
};

export default useInputSpotTrade;
