enum AppUrls {
  DEVELOPMENT = 'https://dev.rmportal.alpha.co.uk',
  STAGING = 'https://preprod.rmportal.alpha.co.uk',
  LIVE = 'https://client.alpha.co.uk',
  PARALLEL = 'https://perf.rmportal.alpha.co.uk',
  DEMO = 'https://demo.alpha.co.uk',
}

interface CognitoConfig {
  poolId: string;
  clientId: string;
  authenticationFlowType?: 'CUSTOM_AUTH';
  domain: string
}

interface EnvironmentConfig {
  REACT_APP_API_URL: string;
  REACT_APP_COGNITO: CognitoConfig;
  REACT_APP_DATA_DOG: DataDogConfig;
  ENVIRONMENT: string;
  ALPHA_CONNECT_URL_HOME: string;
  USERPILOT_TOKEN: string;
}

interface DataDogConfig {
  appId: string;
  clientToken: string;
  service: string;
  env: string;
}

// Default is development
// eslint-disable-next-line import/no-mutable-exports
let environmentVars: EnvironmentConfig = {
  REACT_APP_API_URL: 'https://api.dev.rmportal.alpha.co.uk/',
  REACT_APP_COGNITO: {
    poolId: 'eu-west-1_I1zmjoTbh',
    clientId: '2vfd5h2kjs0e0hvnprbnrek5ph',
    authenticationFlowType: 'CUSTOM_AUTH',
    domain: 'https://rm-portal-banking-dev.auth.eu-west-1.amazoncognito.com',
  },
  REACT_APP_DATA_DOG: {
    appId: '62e2ac7a-cc30-410a-bc51-408d4bd2f185',
    clientToken: 'pubbef63067df5a3b869128476751c49ce5',
    service: 'rm-portal-dev',
    env: 'dev',
  },
  ENVIRONMENT: 'dev',
  ALPHA_CONNECT_URL_HOME: 'https://alpha-connect.rmdev01.alpha.co.uk/connections',
  USERPILOT_TOKEN: 'STG-NX-2d5c85a3',
};

const currentWindowLocation: string = window.location.href;
if (currentWindowLocation.indexOf(AppUrls.LIVE) >= 0) {
  environmentVars = {
    REACT_APP_API_URL: 'https://api.rmportal.alpha.co.uk/',
    REACT_APP_COGNITO: {
      poolId: 'eu-west-1_SFiNiHE2C',
      clientId: '8rpv5em1ln94g2tuehba59mh0',
      authenticationFlowType: 'CUSTOM_AUTH',
      domain: 'https://rm-portal-banking-prod.auth.eu-west-1.amazoncognito.com',
    },
    REACT_APP_DATA_DOG: {
      appId: 'c3d244a0-cc0f-4c1d-af02-d9a1816567ba',
      clientToken: 'pub9cf83474816b6f755feb2408c87aa75f',
      service: 'rm-portal-prod',
      env: 'prod',
    },
    ENVIRONMENT: 'prod',
    ALPHA_CONNECT_URL_HOME: 'https://alpha-connect.alpha.co.uk/connections',
    USERPILOT_TOKEN: 'NX-2d5c85a3',
  };
} else if (currentWindowLocation.indexOf(AppUrls.STAGING) >= 0) {
  environmentVars = {
    REACT_APP_API_URL: 'https://api.preprod.rmportal.alpha.co.uk/',
    REACT_APP_COGNITO: {
      poolId: 'eu-west-1_XK2BqfXYS',
      clientId: '256dassefvs86ab3hqi8gh84m2',
      authenticationFlowType: 'CUSTOM_AUTH',
      domain: 'https://rm-portal-banking-preprod.auth.eu-west-1.amazoncognito.com',
    },
    REACT_APP_DATA_DOG: {
      appId: '7777391d-5938-4345-bb68-e82cd40af291',
      clientToken: 'pubea70474b56ab18f03a65c86c6240567c',
      service: 'rm-portal-preprod',
      env: 'preprod',
    },
    ENVIRONMENT: 'preprod',
    ALPHA_CONNECT_URL_HOME: 'https://alpha-connect.rmpreprod01.alpha.co.uk/connections',
    USERPILOT_TOKEN: 'STG-NX-2d5c85a3',
  };
} else if (currentWindowLocation.indexOf(AppUrls.PARALLEL) >= 0) {
  environmentVars = {
    REACT_APP_API_URL: 'https://api.parallel.rmportal.alpha.co.uk/',
    REACT_APP_COGNITO: {
      poolId: 'eu-west-1_95CoeR7EJ',
      clientId: '49vu80860srn2jic5ttjcc3m9j',
      authenticationFlowType: 'CUSTOM_AUTH',
      domain: 'https://rm-portal-banking-preprod.auth.eu-west-1.amazoncognito.com',
    },
    REACT_APP_DATA_DOG: {
      appId: '0c337635-c5a9-405a-87d1-59a159434817',
      clientToken: 'pubb9552359c8379465c0733016158245d9',
      service: 'rm-portal-perf',
      env: 'perf',
    },
    ENVIRONMENT: 'perf',
    ALPHA_CONNECT_URL_HOME: 'https://alpha-connect.rmpreprod01.alpha.co.uk/connections',
    USERPILOT_TOKEN: 'STG-NX-2d5c85a3',
  };
} else if (currentWindowLocation.indexOf(AppUrls.DEMO) >= 0) {
  environmentVars = {
    REACT_APP_API_URL: 'https://api.demo.alpha.co.uk/',
    REACT_APP_COGNITO: {
      poolId: 'eu-west-1_CIdiF1QHf',
      clientId: '16vt7g3ljckvjka1h3j2chlv87',
      authenticationFlowType: 'CUSTOM_AUTH',
      domain: 'https://rm-portal-banking-demo.auth.eu-west-1.amazoncognito.com',
    },
    REACT_APP_DATA_DOG: {
      appId: '7c368d65-697f-4ff3-9362-e956256efe95',
      clientToken: 'pubef66f22027d622033fa60bb34397e519',
      service: 'demo',
      env: 'demo',
    },
    ENVIRONMENT: 'demo',
    ALPHA_CONNECT_URL_HOME: 'https://alpha-connect.rmpreprod01.alpha.co.uk/connections',
    USERPILOT_TOKEN: 'STG-NX-2d5c85a3',
  };
}

export default environmentVars;
