import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  ({ typography, palette }) => ({
    dropdownWrapperSelected: {
      position: 'relative',
      '& .MuiInputBase-root': {
        paddingLeft: '45px !important',
      },
    },
    dropdown: (props: { dropdownHeight?: string, backgroundColor?: string, fontSize?: string, fontWeight?: number, capitalisedInput?: boolean }) => ({
      '& .MuiFilledInput-root': {
        paddingRight: props.backgroundColor ? '10px !important' : '65px',
      },
      '& .MuiAutocomplete-hasPopupIcon': {
        paddingRight: props.backgroundColor ? '0px !important' : '65px',
      },
      '& .MuiAutocomplete-hasClearIcon': {
        paddingRight: props.backgroundColor ? '0px !important' : '65px',
      },
      '& .MuiAutocomplete-inputRoot': {
        minWidth: props.backgroundColor ? '146px' : 'unset',
        paddingRight: props.backgroundColor ? 'auto !important' : '65px',
        '& .MuiAutocomplete-endAdornment': {
          right: '9px',
          display: props.backgroundColor ? 'flex' : 'unset',
          alignItems: props.backgroundColor ? 'center' : 'unset',
        },
      },
      backgroundColor: 'transparent',
      '& .MuiFormControl-root': {
        '& .MuiInputBase-root': {
          backgroundColor: props.backgroundColor ? props.backgroundColor : palette.grey[100],
          borderRadius: '5px',
          '&::before': {
            borderBottom: 'none',
          },
          '&::after': {
            display: 'none',
          },
          height: props.dropdownHeight ? props.dropdownHeight : 'auto',
          padding: props.backgroundColor ? '15px 0px 0px 13px' : '15px 65px 15px 13px',
          '& ::placeholder': {
            textTransform: 'none',
          },
          '& .MuiInputBase-input': {
            fontSize: props.fontSize ? props.fontSize : '13px',
            padding: 0,
            height: 'auto',
            fontWeight: props.fontWeight ? props.fontWeight : 0,
            textOverflow: props.backgroundColor ? 'unset' : 'ellipsis',
            width: props.backgroundColor ? 'auto' : 'unset',
            textTransform: props.capitalisedInput ? 'uppercase' : 'none',
          },
          '& .MuiAutocomplete-popupIndicator': {
            width: '25px',
            marginTop: props.backgroundColor ? '12px' : '5px',
          },
        },
      },
    }),
    divider: {
      display: 'flex',
      width: '100%',
      borderBottom: '1px solid',
      borderColor: palette.grey[100],
    },
    hideFlag: {
      '& .MuiInputBase-root': {
        paddingLeft: '16px !important',
      },
    },
    output: {
      '&.hideFlag': {
        display: 'none',
      },
    },
    optionIcon: {
      '&.hideFlag': {
        display: 'none',
      },
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      left: '27px',
      transform: 'translate(-50%,-50%)',
    },
    outputText: (props: any) => ({
      fontSize: props.fontSizeOutput ? props.fontSizeOutput : '16px',
      paddingLeft: '5px',
      paddingBottom: '5px',
    }),
    labelWrapper: {
      display: 'flex',
      alignitems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    extraInfoWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    paymentInfo: {
      textAlign: 'left',
      margin: 'auto',
      fontWeight: typography.fontWeightLight,
      color: 'rgb(51, 51, 51, 0.5)',
      marginRight: '8px',
      paddingLeft: '5px',
      marginLeft: '5px',
      paddingBottom: '5px',
    },
    selectedCheck: {
      color: palette.primary.main,
      marginLeft: 'auto',
      alignSelf: 'center',
    },
  }),
  { name: 'AutocompleteDropDown' },
);

export default useStyles;
