import React, { useEffect, useState } from 'react';
import Big from 'big.js';
import moment from 'moment';
import { useMutation, useQuery } from 'react-query';
import t from 'utils/translationHelper';
import * as yup from 'yup';

import { BeneficiaryDto } from '@alpha/bene-dtos';
import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';
import {
  ManualPaymentRequest, PaymentDto,
  PaymentPurposeDto,
  PaymentStatus,
} from '@alpha/payments-dtos';
import { ActionButton, IconButton } from '@alpha/ui-lib/ui/button';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { StyledTabsWrapper } from '@alpha/ui-lib/ui/Tabs';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useForm from '../../../../hooks/useForm';
import useLog from '../../../../hooks/useLog';
import instance from '../../../../services/Axios/instance';
import PaymentsService from '../../../../services/Payments/payments.service';
import timer from '../../../../utils/timer';
import CurrencyPair from '../../../International/CurrencyPair/CurrencyPair';
import DrawerBackdropLoader from '../../Loaders/DrawerBackdropLoader/DrawerBackdropLoader';
import { FundingOption, Section } from '../CreatePaymentDrawer';
import { FormikPaymentType, FormikValueProps } from '../CreatePaymentDrawer/formData';
import Summary from '../Summary';

import { initialValues } from './AddPayment/formData';
import PaymentIncompleteWarningModal from './AddPayment/PaymentIncompleteWarningModal';
import AddPayment from './AddPayment';
import useStyles from './Beneficiaries.styles';

type ValidatePaymentParams = {
  data: FormikPaymentType,
  paymentCurrencyAccount?: CurrencyAccountDto,
  sellCurrencyAccount?: CurrencyAccountDto,
  draftPaymentId: string | null,
};

type ValidatePaymentResponse = {
  errors: string[],
  id: string,
  status: PaymentStatus,
}

type Props = {
  handleSetSection: (_section: Section) => void
  paymentDraft?: PaymentDto
  tradePayments?: boolean
  tradeBuyAmount?: number
  totalTradePayments?: number
  tradeId?: string
  tradeDraftId?: string
  fundingMethod: FundingOption
  onDrawerClose?: any
  setCreateBeneficiaryOpen?: React.Dispatch<React.SetStateAction<boolean | undefined>>
  setBeneToUpdate?: React.Dispatch<React.SetStateAction<BeneficiaryDto | undefined>>
  refetchBene: number;
} & FormikValueProps;

const determinePurpose = (purpose?: PaymentPurposeDto | string, invoiceDate?: string, invoiceNumber?: string): string => {
  if (purpose && typeof purpose === 'object') {
    if (purpose?.type === 'Service' && invoiceDate && invoiceNumber && typeof purpose === 'object') {
      return `${purpose?.description.replace(' -', ',')}, INVOICE NO.- ${invoiceNumber}, INVOICE DATE:- ${invoiceDate}`;
    }

    if (purpose?.type === 'Unspecified' && typeof purpose === 'object') {
      return `${purpose?.description.replace(' -', ',')}`;
    }

    return purpose.code;
  }

  if (purpose && typeof purpose === 'string') {
    return purpose;
  }

  return '';
};

const validatePayment = async (
  {
    data, paymentCurrencyAccount, sellCurrencyAccount, draftPaymentId,
  }: ValidatePaymentParams,
) => {
  const response = await instance.post<ManualPaymentRequest, any>('/payments/initiate', {
    batchId: '',
    existingDraftPaymentId: data.id ?? draftPaymentId,
    fundingAccountId: sellCurrencyAccount?.id,
    beneficiaryId: data.beneficiary!.id,
    emailAddresses: data.emailAddresses,
    amount: +data.amount!,
    fundingCurrency: sellCurrencyAccount?.currencyCode,
    debitingAccountId: paymentCurrencyAccount!.id,
    debitingCurrency: paymentCurrencyAccount!.currencyCode,
    tradeId: data.tradeId,
    paymentOrigin: data.paymentOrigin,
    tradeDraftId: data.tradeDraftId,
    paymentDate: moment(data.date!).format('DD/MM/yyyy'),
    reference: data.reference || '',
    purpose: determinePurpose(data.purpose, data?.invoiceDate, data?.invoiceNumber),
  });
  return response.data;
};

// eslint-disable-next-line max-lines-per-function
const Beneficiaries: React.FC<Props> = (props) => {
  const {
    values, // PaymentsFormValues
    paymentDraft,
    setFieldValue,
    handleSetSection,
    tradePayments,
    tradeBuyAmount,
    totalTradePayments,
    tradeId,
    tradeDraftId,
    fundingMethod,
    onDrawerClose,
    setCreateBeneficiaryOpen,
    setBeneToUpdate,
    refetchBene,
  } = props;

  const paymentLimitPlaceholder = 1000000;
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [maxPaymentLimit, setMaxPaymentLimit] = useState<number>(paymentLimitPlaceholder);
  const [loading, setLoading] = useState(false);
  const [draftPaymentId, setDraftPaymentId] = useState<string>();
  const sb = useAlphaSnackbar();
  const { logError, logEvent } = useLog();
  const classes = useStyles();
  const [needMoreInfo, setNeedMoreInfo] = useState<boolean>(false);

  const setFormData = (id: string) => {
    const payment = { ...singlePaymentForm.values, id };
    setFieldValue('payments', values.payments ? [...values.payments, payment] : [payment]);
    singlePaymentForm.resetForm();
    sb.trigger(t('payment_added'), 'success');
    logEvent({ action: 'Successfully added payment' });
    if (singlePaymentForm.values.singlePayment) {
      handleSetSection('Send');
    }
  };

  const loadPaymentData = async (id: string): Promise<PaymentDto> => {
    try {
      setLoading(true);
      const result = await PaymentsService.getSinglePayment(id);
      return result;
    } catch (error) {
      sb.trigger(error?.message || t('failed_to_load_payment'));
      logError({ action: 'Error loading payment', error });
      return [];
    }
  };

  const pollSinglePayment = async (retry = 6, delay = 1000, id: string) => {
    const statusToPoll = [PaymentStatus.VALIDATED, PaymentStatus.ERRORED, PaymentStatus.INVALID];
    setLoading(true);
    const record = await loadPaymentData(id);

    // eslint-disable-next-line max-len
    if (retry === 0 || record.status === PaymentStatus.INVALID || record.status === PaymentStatus.ERRORED) {
      if (record.errors.length) {
        record.errors.forEach((error) => {
          sb.trigger(
            `${error}`,
            'error',
            {
              persist: true,
              action: (key: string) => (
                <IconButton
                  icon={faTimes}
                  onClick={() => {
                    sb.close(key);
                  }}
                  style={{ color: '#fff', fontSize: '12px', display: 'inline-block' }}
                />
              ),
            },
          );

          logError({ action: `Error validating single payment - ${error}` });
        });
      } else {
        sb.trigger(`${t('there_was_an_error_validating_your_payment')}.`, 'error');
      }

      logError({ action: 'Error validating single payment' });
      setDraftPaymentId(id);
      setLoading(false);
      return;
    }
    await timer(delay);
    // eslint-disable-next-line max-len
    if (!statusToPoll.includes(record.status)) {
      pollSinglePayment(retry - 1, 1000, id);
    }
    // eslint-disable-next-line max-len
    if (record.status === PaymentStatus.VALIDATED) {
      setLoading(false);
      setFormData(id);
    }
  };

  const validatePaymentMutations = useMutation(validatePayment, {
    onSuccess: (data: ValidatePaymentResponse) => {
      pollSinglePayment(6, 1000, data.id);
    },
    onError: (error: any) => {
      sb.trigger(t('error_validating_payment_please_try_again_later'), 'error');
      logError({ action: 'Error validating payment' });
    },
  });

  const validation = {
    beneficiary: yup
      .object({
        id: yup.string().required(),
      })
      .required(t('please_select_a_beneficiary_account')),
    amount: yup
      .number()
      .typeError(t('please_enter_numbers_only'))
      .required(t('please_select_an_amount'))
      .positive(t('please_enter_an_amount_greater_than_zero'))
      .max(maxPaymentLimit, t('amount_is_above_the_maximum_payment_limit')),
    date: yup.string().required(t('please_specify_a_payment_date')),
    reference: yup.string().nullable()
      .matches(/^[A-Za-z0-9.,?/:()+‘’' -]*$/, t('special_characters_are_not_allowed'))
      .test('len', t('must_be_255_characters_or_less'), (val) => {
        if (!val) return true;
        return val?.length <= 255;
      }),
    emailAddresses: yup.array().of(yup.string().email(t('invalid_email_address'))),
    purposeType: yup.string().oneOf(['Service', 'Unspecified']).nullable(),
    purpose: yup.string().test('len', t('must_be_255_characters_or_less'), (val) => {
      if (!val) return true;
      return val?.length <= 255;
    }),
    invoiceNumber: yup.string().when('purposeType', {
      is: 'Service',
      then: yup.string().required(t('invoice_number_is_a_required_field'))
        .matches(/^[A-Za-z0-9.,?/:()+‘’' -]*$/, t('special_characters_are_not_allowed')),
    }).nullable(),
    invoiceDate: yup.string().when('purposeType', {
      is: 'Service',
      then: yup.string().required(t('invoice_date_required_for_purpose_code_service')),
    }).nullable(),
  };

  const accountPaymentConfigQuery = useQuery('getAccountConfig', () => PaymentsService.getAccountConfiguration(
    values.paymentCurrencyAccount?.currencyCode || '',
    (fundingMethod === 'Spot' ? values.sellCurrencyAccount?.currencyCode : values.paymentCurrencyAccount?.currencyCode) || '',
  ), {
    enabled: true,
    onError: () => {
      sb.trigger(t('could_not_load_account_configurations'));
      logError({ action: 'Error loading account configurations' });
    },
  });

  useEffect(() => { if (paymentDraft) setFieldValue('isDraftPayment', true); }, []);

  useEffect(() => {
    setMaxPaymentLimit(accountPaymentConfigQuery.data?.maximumPayment?.buyAmount
      || paymentLimitPlaceholder);
  },
  [
    accountPaymentConfigQuery.data?.maximumPayment?.buyAmount,
  ]);

  const tradeHasEnoughBalance = (
    paymentsAmount = 0,
    buyAmount = 0,
    incomingPayment = 0,
  ): boolean => Big(buyAmount).minus(paymentsAmount).minus(incomingPayment).gte(0);

  const singlePaymentForm = useForm(initialValues, validation, (v: FormikPaymentType) => {
    if ((tradePayments && tradeHasEnoughBalance(
      totalTradePayments, tradeBuyAmount, v.amount,
    )) || (!tradePayments)) {
      validatePaymentMutations.mutate({
        data: {
          ...v, tradeId, tradeDraftId, paymentOrigin: values.paymentOrigin,
        },
        paymentCurrencyAccount: values.paymentCurrencyAccount,
        sellCurrencyAccount: values.sellCurrencyAccount,
        draftPaymentId: draftPaymentId ?? null,
      });
    } else {
      sb.trigger(t('there_is_not_enough_trade_balance_for_this_payment'), 'error');
      logError({ action: 'Not enough trade balance for payment' });
    }
  }, true);

  useEffect(() => {
    if (singlePaymentForm.values.beneficiary && setBeneToUpdate) {
      setBeneToUpdate(singlePaymentForm.values.beneficiary);
    }
    if (!singlePaymentForm.values.beneficiary) {
      setNeedMoreInfo(false);
    }
  }, [singlePaymentForm]);

  const tabTitles: string[] = [t('add_payment')];
  if (!paymentDraft) tabTitles.push(`${t('summary')} (${values.payments?.length || 0})`);
  const {
    amount, emailAddresses, beneficiary, reference, purpose,
  } = singlePaymentForm.values;

  const isTouched = Boolean(amount || emailAddresses.length || beneficiary || purpose || reference);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {!tradePayments && fundingMethod === 'Account' && (
          <>
            <div style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '16px',
            }}
            >
              <Typography>{t('funding_method')}</Typography>
              <Typography style={{ display: 'flex' }}>
                <CurrencyPair currencyCode={values.paymentCurrencyAccount?.currencyCode || ''} displayCode={false} />
                {values.paymentCurrencyAccount?.accountName}
              </Typography>
            </div>
            <div style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '16px',
            }}
            >
              <Typography>{t('currency')}</Typography>
              <Typography style={{ fontWeight: 'bold' }}>{values.paymentCurrencyAccount?.currencyCode}</Typography>
            </div>
          </>
        )}

        {fundingMethod === 'Spot' && (
          <>
            <div style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '16px',
            }}
            >
              <Typography>{t('funding_method')}</Typography>
              <Typography style={{ display: 'flex' }}>
                {t('spot_trade')}
              </Typography>
            </div>
            <div style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '16px',
            }}
            >
              <Typography>{t('payment_currency_(buy)')}</Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                <CurrencyPair currencyCode={values.paymentCurrencyCode || ''} displayCode />
              </Typography>
            </div>
            <div style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '16px',
            }}
            >
              <Typography>{t('sell_currency')}</Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                <CurrencyPair currencyCode={values.sellCurrencyCode || ''} displayCode />
              </Typography>
            </div>
          </>
        )}
        <div>
          <StyledTabsWrapper
            testId="payment-tabs-wrapper"
            tabTitles={tabTitles}
            tabIndex={tabIndex}
            handleChange={(selectedTabIndex: number) => setTabIndex(selectedTabIndex)}
          />
          <div style={{
            transform: 'translateY(-8px)', width: '100%', left: 0,
          }}
          >
            <BaseDrawer.LineBreak />
          </div>
          <div style={{ marginTop: '24px' }}>
            {
              tabIndex === 0 && (
                <AddPayment
                  setFieldValue={setFieldValue}
                  values={values}
                  singlePaymentForm={singlePaymentForm}
                  needMoreInfo={needMoreInfo}
                  setNeedMoreInfo={setNeedMoreInfo}
                  refetchBene={refetchBene}
                />
              )
            }
            {
              tabIndex === 1 && (
                <Summary values={values} setFieldValue={setFieldValue} />
              )
            }
          </div>
        </div>
        {needMoreInfo ? (
          <div style={{
            marginBottom: '29px',
            width: '100%',
            marginTop: '20px',
            gridGap: 10,
            display: 'flex',
          }}
          >
            <ActionButton
              className={classes.clearButton}
              onClick={() => {
                singlePaymentForm.values.beneficiary = undefined;
                setNeedMoreInfo(false);
              }}
            >
              {t('clear')}
            </ActionButton>
            <ActionButton
              className={classes.updateInfoButton}
              onClick={() => {
                if (setCreateBeneficiaryOpen) {
                  setCreateBeneficiaryOpen(true);
                }
              }}
            >
              {t('update_information')}
            </ActionButton>
          </div>
        ) : (
          <div style={{
            display: 'flex', flexDirection: 'row', padding: '92px 0 42px 0', justifyContent: 'space-between',
          }}
          >
            <div style={{
              position: 'absolute', transform: 'translateY(-42px)', width: '100%', left: 0,
            }}
            >
              <BaseDrawer.LineBreak />
            </div>
            <Box alignItems="start-end end" display="flex" justifyContent={tradePayments ? 'end' : 'space-between'} flexGrow={1} flexDirection="row">
              {!tradePayments && (
                <ActionButton
                  style={{ background: '#F7F7F7', color: '#212529' }}
                  onClick={() => {
                    handleSetSection('Funding');
                    setFieldValue('payments', []);
                  }}
                >
                  {t('back_to_funding')}
                </ActionButton>
              )}
              <ActionButton
                disabled={!values.payments?.length && !singlePaymentForm.isValid}
                onClick={() => {
                  if (!isTouched) {
                    handleSetSection('Send');
                  } else if (!singlePaymentForm.isValid && values.payments?.length) {
                    setOpenModal(true);
                  } else {
                    singlePaymentForm.setFieldValue('singlePayment', true);
                    singlePaymentForm.submitForm();
                  }
                }}
                size="medium"
              >
                {t('continue_to_confirmation')}
              </ActionButton>
            </Box>
          </div>
        )}
      </div>
      <PaymentIncompleteWarningModal handleClose={() => setOpenModal(false)} open={openModal} handleSubmit={() => handleSetSection('Send')} />
      <DrawerBackdropLoader display={validatePaymentMutations.isLoading || loading} text={t('validating_your_payment')} />
    </>
  );
};

export default Beneficiaries;
