import React from 'react';
import { Amplify } from 'aws-amplify';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Userpilot } from 'userpilot';

import theme, { ThemeProvider } from '@alpha/ui-lib/theme';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import App from './App';
import awsExports from './aws-exports';
import env from './env.variables';
import * as serviceWorker from './serviceWorker';
import { store } from './store';

import 'i18n/config';

Amplify.configure(awsExports);
const queryClient = new QueryClient();

datadogRum.init({
  applicationId: env.REACT_APP_DATA_DOG.appId,
  clientToken: env.REACT_APP_DATA_DOG.clientToken,
  site: 'datadoghq.eu',
  service: env.REACT_APP_DATA_DOG.service,
  env: env.REACT_APP_DATA_DOG.env,
  // Specify a version number to identify the deployed version of your application in Datadog
  version: process.env.REACT_APP_VERSION,
  sampleRate: 100,
  premiumSampleRate: 100,
  trackUserInteractions: true,
  trackFrustrations: true,
  defaultPrivacyLevel: 'allow',
  enableExperimentalFeatures: ['clickmap'],
});

datadogLogs.init({
  clientToken: env.REACT_APP_DATA_DOG.clientToken,
  site: 'datadoghq.eu',
  forwardErrorsToLogs: true,
  sampleRate: 100,
  service: env.REACT_APP_DATA_DOG.service,
  env: env.REACT_APP_DATA_DOG.env,
});

datadogRum.startSessionReplayRecording();

Userpilot.initialize(env.USERPILOT_TOKEN);

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
