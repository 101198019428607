import { PaymentRouteEnum } from './models/payments';

const routes = {
  transactions: {
    base: '/app/transactions',
    fxtrades: '/app/transactions/fx-trades',
    transfer: '/app/transactions/transfer',
    optionTrades: '/app/transactions/option-trades',
    optionTrade: '/app/transactions/option-trades/:tradeId',
    paymentUpload: '/app/transactions/payment-upload',
    payments: '/app/payments/:batchId',
    manual: {
      base: '/app/transactions/manual',
      single: '/app/transactions/manual/single', // deprecated
      batch: '/app/transactions/manual/batch',
    },
    trades: '/app/trades/:tradeId',
    emptyTrades: '/app/trades/',
    uploadSummary: '/app/transactions/upload-summary/:batchId',
    funding: '/app/transactions/:batchId/funding',
    batchSummaryRouteFunc: (batchId: string, state?: PaymentRouteEnum) => {
      let url = `/app/transactions/batch-summary/${batchId}`;

      switch (state) {
        case PaymentRouteEnum.BASE:
        default:
          url = `${url}/`;
          break;
        case PaymentRouteEnum.FX_TRADES:
          url = `${url}/fx-trades`;
          break;
      }
      return url;
    },
  },
  accountSettings: '/app/account-settings',
  dashboard: '/app/dashboard',
  beneficiaries: '/app/beneficiaries',
  batchBeneficiariesSummary: '/app/beneficiaries/upload-summary/:id',
  createBeneficiary: '/app/beneficiaries/create-beneficiary',
  currencyAccounts: '/app/currency-accounts',
  noStatements: '/app/statements',
  statements: '/app/statements/:currencyAccountId',
  marketInformation: '/app/market-information',
  marketOrders: '/app/market-orders',
  hedgingPrograms: '/app/hedging-programs',
  profile: '/app/profile',
  home: '/app',
  noAccountsFound: '/auth/no-accounts',
  invalidSession: '/auth/invalid-session',
  base: '/',
  auth: {
    base: '/auth/',
    onboarding: {
      base: '/auth/onboarding',
      login: '/auth/onboarding/verify-email',
      newPassword: '/auth/onboarding/new-password',
      setupMfa: '/auth/onboarding/setup-mfa',
      verifyMfa: '/auth/onboarding/verify-mfa',
      welcome: '/auth/onboarding/welcome',
      mfaOptions: '/auth/onboarding/mfa-options',
    },
    login: '/auth/login',
    welcome: '/auth/welcome',
    totp: '/auth/totp',
    newPassword: '/auth/new-password',
    mfaOptions: '/auth/mfa-options',
    custom: '/auth/custom',
    passwordReset: {
      base: '/auth/password-reset',
      request: '/auth/password-reset/request',
      verifyMfa: '/auth/password-reset/verify-mfa',
      success: '/auth/password-reset/success',
    },
  },
  credit: '/app/credit-facility',
  creditTerm: '/app/credit-facility/term/:termId',
};

export default routes;
