import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    drawer: {
      minWidth: '605px',
      position: 'relative',
      minHeight: '100vh',
      '& .MuiDrawer-paper': {
        maxWidth: '600px',
        '& > button:first-of-type': {
          zIndex: 1,
        },
      },
    },
    container: (props: any) => ({
      padding: '20px',
      minHeight: 'calc(100% - 55px)',
    }),
    footer: {
      margin: '32px 0',
    },
    icon: {
      color: '#1E8777',
      width: 26,
      height: 26,
      verticalAlign: 'middle',
    },
    heading: {
      fontSize: '22px',
      fontWeight: 400,
      paddingLeft: '20px',
    },
    bookedHeading: {
      fontSize: '22px',
      fontWeight: 400,
      paddingLeft: '7px',
    },
    verticalDivider: {
      margin: '9px',
    },
  }),
  { name: 'CreateSpotDrawer' },
);

export default useStyles;
