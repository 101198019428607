import React, { memo, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import useLog from 'hooks/useLog';
import i18n from 'i18n/config';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect, Route, RouteProps,
} from 'react-router-dom';
import AuthService from 'services/Auth/auth.service';
import t from 'utils/translationHelper';

import { datadogRum } from '@datadog/browser-rum';

import BackdropLoader from '../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import useAlphaSnackbar from '../hooks/useAlphaSnackbar';
import useAuth from '../hooks/useAuth';
import useProfile from '../hooks/useProfile';
import useSwitchAccount from '../hooks/useSwitchAccount';
import routes from '../routes.path';
import history from '../services/history/browserHistory';
import { TStore } from '../store';
import { actions as clientNotificationsActions } from '../store/clientNotifications/clientNotifications.reducer';
import { actions as creditActions } from '../store/creditFacility/reducer';

const PrivateRoute: React.FC<RouteProps> = (props: RouteProps) => {
  const {
    checkAuth, loggedIn, userInfo, handleSignOut,
  } = useAuth();
  const [cognitoUser, setCognitoUser] = useState<any>();
  const { userProfile, updateUserProfile } = useProfile();
  const { currentAccount, setUserAccount, isValidAccountId } = useSwitchAccount();
  const key = useSelector<TStore, number>((store) => store.user.key);
  const userId = useSelector<TStore, string | undefined>((store) => store.user.profileDetails?.id);
  const sb = useAlphaSnackbar();
  const { isPageViewable } = useSwitchAccount();
  const dispatch = useDispatch();
  const { logError } = useLog();

  const updateFirstLangPreference = async () => {
    try {
      await AuthService.updateFirstLanguagePreference(false);
    } catch (e) {
      logError({ action: 'Error updating first language preference', error: e });
    }
  };

  const updateLangProfile = async () => {
    try {
      await AuthService.updateLangPreference(i18n.language);
    } catch (e) {
      logError({ action: 'Error updating language preference', error: e });
    }
  };

  useEffect(() => {
    if (userProfile?.firstLanguagePreference || userProfile?.firstLanguagePreference === null) {
      updateFirstLangPreference();
      updateLangProfile();
    } else if (userProfile?.langPreference) {
      i18n.changeLanguage(userProfile.langPreference);
      localStorage.setItem('authLanguagePreference', userProfile.langPreference);
    }
  }, [userProfile]);

  const setUserAccountDetails = async (): Promise<void> => {
    try {
      await checkAuth();
      if (loggedIn && userInfo) {
        if (!currentAccount || !isValidAccountId) await setUserAccount();
        if (!userProfile) await updateUserProfile();
      }
    } catch (error) {
      sb.trigger(error?.message || t('could_not_load_your_account_details'));
      logError({ action: 'Error loading account details', error });
      handleSignOut();
    }
  };

  const getCognitoUserDetails = async () => {
    try {
      const cognitoUserDetails = await Auth.currentAuthenticatedUser();
      setCognitoUser(cognitoUserDetails);
    } catch (error) {
      sb.trigger(error.message || t('there_was_an_error_getting_your_user_details'));
      logError({ action: 'Error loading currentAuthenticatedUser', error });
    }
  };

  useEffect(() => {
    if (userId && cognitoUser) {
      datadogRum.setUser({
        id: userId,
        name: cognitoUser?.attributes?.name || '-',
        email: cognitoUser?.attributes?.email || '-',
      });
    } else if (!userId) {
      datadogRum.removeUser();
    }
  }, [cognitoUser, userId]);

  useEffect(() => {
    setUserAccountDetails();
    if (!isPageViewable(history.location.pathname)) {
      history.push(routes.dashboard);
    }
    if (userInfo) {
      getCognitoUserDetails();
    }

    if (loggedIn && userInfo && (history.location.pathname.includes('credit') || history.location.pathname.includes('dashboard'))) {
      dispatch(creditActions.loadCreditFacility());
      if (history.location.pathname.includes('term')) {
        const termId = history.location.pathname.split('/').slice(-1);
        dispatch(creditActions.loadTerm({ termId }));
      }
    }
  }, [loggedIn, history.location.pathname, userInfo]);

  useEffect(() => {
    if (loggedIn) {
      dispatch(clientNotificationsActions.load());
      dispatch(clientNotificationsActions.show());
      dispatch({ type: 'GET_FEATURE_FLAGS_REQUEST' });
      dispatch(creditActions.loadCreditFacility());
      if (history.location.pathname.includes('term')) {
        const termId = history.location.pathname.split('/')?.slice(-1);
        dispatch(creditActions.loadTerm({ termId }));
      }
    }
  }, [loggedIn]);

  const showRoute = (): React.ReactNode => {
    if (loggedIn && isValidAccountId) {
      return <Route {...props} />;
    }
    if (loggedIn === false) {
      return <Redirect to={routes.auth.login} />;
    }
    // Need to revisit determining valid access to a given account from the request to fetch user-accounts
    // if (loggedIn && isValidAccountId === false) {
    //   logEvent({
    //     action: 'No Access to Account, Redirect to noAccountsFound Route',
    //     detail: {
    //       loggedIn, isValidAccountId, currentAccount, userProfile,
    //     },
    //   });
    //   return <Redirect to={routes.noAccountsFound} />;
    // }
    return <BackdropLoader testId="backdrop-loader" value={100} />;
  };

  return <div key={key}>{showRoute()}</div>;
};

export default memo(PrivateRoute);
