import { BeneficiaryDto } from '@alpha/bene-dtos';
import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';
import { TradeFundingMethod } from '@alpha/fx-dtos';

export type TSpotFXRequestForm = {
  fundingMethod: TradeFundingMethod;
  sellCurrencyCode: string;
  buyCurrencyCode: string;
  fixedCurrencyCode: string;
  fixedAmount: number;
  currencyPair: string,
  sellAmount: number;
  buyAmount: number;
  indicativeRate: number;
  draftInitiated: boolean;
  valueDate: string;
  tradeId: string;
  quoteRate?: number;
  reference?: string;
  beneficiary?: BeneficiaryDto;
  fundingCurrency?: string;
  selectedBuyCurrencyAccount?: CurrencyAccountDto;
  selectedSellCurrencyAccount?: CurrencyAccountDto;
  defaultSellCurrencyAccount?: CurrencyAccountDto;
  defaultBuyCurrencyAccount?: CurrencyAccountDto;
  payments?: [];
  paymentSubmitted?: boolean;
};

export const initialValues: TSpotFXRequestForm = {
  fundingMethod: TradeFundingMethod.CURRENCY_ACCOUNT_BALANCE,
  sellCurrencyCode: '',
  buyCurrencyCode: '',
  fixedCurrencyCode: '',
  fixedAmount: 0.00,
  sellAmount: 0.00,
  buyAmount: 0.00,
  indicativeRate: 0,
  draftInitiated: false,
  valueDate: '',
  tradeId: '',
  currencyPair: '',
  selectedBuyCurrencyAccount: undefined,
  selectedSellCurrencyAccount: undefined,
  defaultSellCurrencyAccount: undefined,
  defaultBuyCurrencyAccount: undefined,
  payments: [],
  paymentSubmitted: false,
};

export default initialValues;
