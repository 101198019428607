import React, { useEffect } from 'react';
import FormTitle from 'components/Molecules/Auth/FormTitle';
import useLogin from 'hooks/useLogin';
import useQueryString from 'hooks/useQueryString';
import { NavLink } from 'react-router-dom';
import routes from 'routes.path';
import { Userpilot } from 'userpilot';
import t from 'utils/translationHelper';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { APInput } from '@alpha/ui-lib/ui/Input';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import useFormStyles from '../form.styles';
import LangSelectorGroup from '../LangSelectorGroup';
import { useOnboardingContext } from '../Onboarding';

interface IProps {
  onboarding?: boolean;
}

const Login: React.FC<IProps> = ({ onboarding }: IProps) => {
  const formClasses = useFormStyles();
  const { formik, loading } = useLogin(onboarding, useQueryString('fromAlphaConnect', '')[0]);

  const { handleSetLoaderPercentage } = useOnboardingContext();
  const onboardingDescription = t('to_get_started_please_enter_your_username_and_temporary_password');

  useEffect(() => {
    if (handleSetLoaderPercentage) {
      handleSetLoaderPercentage(25);
    }
    Userpilot.clean();
  }, []);

  return (
    <div className={formClasses.wrapperDiv}>
      <div>
        <FormTitle
          number={onboarding ? 1 : undefined}
          title={onboarding ? t('verify_your_email') : t('sign_in')}
          description={onboarding ? onboardingDescription : undefined}
        />
        <form onSubmit={formik.handleSubmit}>
          <div className={formClasses.section}>
            <Typography className={formClasses.label}>{t('enter_your_username')}</Typography>
            <APInput
              autoComplete="off"
              autoFocus
              testid="username"
              id="username"
              name="username"
              placeholder={t('enter_your_username')}
              fullWidth
              variant="filled"
              type="text"
              inputProps={{
                autoComplete: 'off',
                form: {
                  autoComplete: 'new-off',
                },
              }}
              onChange={formik.handleChange}
              value={formik.values.username}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
          </div>
          <div className={formClasses.section} data-dd-privacy="hidden">
            <Typography className={formClasses.label}>
              {onboarding ? t('enter_your_temporary_password') : t('enter_your_password')}
            </Typography>
            <APInput
              autoComplete="off"
              testid="password"
              id="password"
              name="password"
              type="password"
              placeholder={t('enter_your_password')}
              variant="filled"
              fullWidth
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </div>
          <ActionButton
            size="large"
            type="submit"
            testId="submit-login"
            loading={loading}
            disabled={loading || !formik.isValid}
            fullWidth
          >
            {onboarding ? t('set_up_account^') : t('log_in^')}
          </ActionButton>
          {!onboarding && (
            <Typography className={formClasses.formBacklink}>
              <span className={formClasses.white}>
                {t('forgot_your_password')}
              </span>
              {' '}
              <NavLink to={routes.auth.passwordReset.base}>{t('reset_password')}</NavLink>
            </Typography>
          )}
        </form>
      </div>
      <LangSelectorGroup />
    </div>
  );
};

export default Login;
