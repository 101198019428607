import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import IATDrawerContainer from 'components/Drawer/IATDrawer/IATDrawerContainer';
import ApprovalDrawer from 'components/Molecules/Payments/ApprovalDrawer';
import CreatePaymentDrawer from 'components/Molecules/Payments/CreatePaymentDrawer';
import RejectPaymentModal from 'components/Molecules/Payments/RejectPaymentModal';
import CreateForwardTradeDrawer from 'components/Molecules/Trade/CreateForwardTradeDrawer';
import CreateSpotDrawer from 'components/Molecules/Trade/CreateSpotDrawer/index';
import Authorization from 'hocs/Authorization';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useAuthorization from 'hooks/useAuthorization';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';
import useLog from 'hooks/useLog';
import useQueryString from 'hooks/useQueryString';
import useSwitchAccount from 'hooks/useSwitchAccount';
import i18n from 'i18n/config';
import { UserRole } from 'models/user';
import { useQuery } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import { FeatureFlagName } from 'services/Config/config.service';
import FXTradeService from 'services/FXTrade/fxTrade.service';
import browserHistory from 'services/history/browserHistory';
import isFxMarketClosed from 'utils/fxTrades/isFxMarketClosed';
import t from 'utils/translationHelper';

import {
  TradeDto, TradeStatus, TradeType,
} from '@alpha/fx-dtos';
import { PaymentDto, PaymentStatus } from '@alpha/payments-dtos';
import { TText } from '@alpha/ui-lib/interfaces';
import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { DateRangePicker } from '@alpha/ui-lib/ui/DatePicker';
import {
  Box, Button, Collapse, IconButton,
} from '@alpha/ui-lib/ui/external';
import { Card } from '@alpha/ui-lib/ui/layout/Card';
import { APMainLayout } from '@alpha/ui-lib/ui/MainLayout';
import APFilterMenu from '@alpha/ui-lib/ui/molecules/Menu/FilterMenu';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import {
  MultiAutoCompleteSelect,
} from '@alpha/ui-lib/ui/Select';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDown, faCalendarAlt, faChevronDown,
  faSlidersH,
} from '@fortawesome/pro-light-svg-icons';
import { faArrowUp, faList, faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import routes from '../../../routes.path';
import BeneficiaryInfoDrawer from '../BatchSummary/PaymentSummary/BeneficiaryInfoDrawer/BeneficiaryInfoDrawer';
import PaymentInfoDrawer from '../PaymentInfoDrawer/PaymentInfoDrawer';

import useOptionTradesFilter from './Filter/useOptionTadesFilter';
import usePaymentFilter from './Filter/usePaymentFilter';
import useTradeFilter from './Filter/useTradeFilter';
import useTransferFilter from './Filter/useTransferFilter';
import FxSummaryTable from './FxTradeTable/FxSummaryTable';
import FxTradeTable, { TFilter } from './FxTradeTable/FxTradeTable';
import { TSearchFilterTypes } from './FxTradeTable/useFxTradeTable';
import OptionTradesTable from './OptionTradesTable/OptionTradesTable';
import { TOptionTradesSearchFilterTypes } from './OptionTradesTable/useOptionTradesTable';
import PaymentTableContainer from './PaymentTableContainer/PaymentTableContainer';
import { TPaymentSearchFilterTypes } from './PaymentTableContainer/usePaymentTable';
import Tabs from './Tabs/Tabs';
import TransferTable from './TransferTable/TransferTable';
import { TTransferSearchFilterTypes } from './TransferTable/useTransferTable';
import useStyles from './Dashboard.styles';
import useMenuStyles from './MenuCustomStyle.styles';

// eslint-disable-next-line max-lines-per-function
const Dashboard: React.FC = () => {
  //  Gerneral
  const { homePageUrl, isEMoneyDisabled } = useSwitchAccount();
  const sb = useAlphaSnackbar();
  const fxMarketClosed = isFxMarketClosed();

  const classes = useStyles({ fxMarketClosed });
  const { logEvent, logError } = useLog();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [disableFilterBackdropClick, setDisableFilterBackdropClick] = useState<boolean>(false);
  const [openSpotMenu, setOpenSpotMenu] = useState<null | HTMLElement>(null);
  const [firstPartyFlow, setFirstPartyFlow] = useState(false);

  // Payments
  const [createPaymentsDrawerOpen,
    setCreatePaymentsDrawerOpen] = useState(false);
  const [multiSelectedPayments,
    setMultiSelectedPayments] = useState<PaymentDto[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [paymentsToReject,
    setPaymentsToReject] = useState<PaymentDto[]>([]);
  const [paymentsToApprove,
    setPaymentsToApprove] = useState<PaymentDto[]>([]);
  const [selectedPayment, setSelectedPayment] = useState<PaymentDto>();
  const [selectedPaymentBeneficiaryId,
    setSelectedPaymentBeneficiaryId] = useState<string | undefined>(undefined);
  const [approvalDrawerOpen,
    setApprovalDrawerOpen] = useState<boolean>(false);
  const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);
  const [rejectedPaymentIds, setRejectedPaymentIds] = useState<string[]>([]);

  const [paymentCurrencies, setPaymentCurrencies] = useState<string[]>([]);
  const [paymentStatuses, setPaymentStatuses] = useQueryString('paymentStatus', []);
  const [paymentValueStartDate, setPaymentValueStartDate] = useState<string>();
  const [paymentValueEndDate, setPaymentValueEndDate] = useState<string>();
  const [paymentFilters, setPaymentFilters] = useState<TFilter[]>([]);
  const [paymentFiltersByField, setPaymentFiltersByField] = useState<TPaymentSearchFilterTypes>();
  const { authorized: isPaymentFileInputter } = useAuthorization(
    [[UserRole.PAYMENT_FILE_INPUTTER]],
  );
  const { authorized: isForwardInputter } = useAuthorization(
    [[UserRole.FORWARD_INPUTTER]],
  );
  const { isFlagActive } = useGetFeatureFlags();

  const paymentFilter = usePaymentFilter({
    paymentCurrencies,
    paymentStatuses,
    paymentValueStartDate,
    paymentValueEndDate,
    setPaymentCurrencies,
    setPaymentStatuses,
    setPaymentValueStartDate,
    setPaymentValueEndDate,
    setDisableFilterBackdropClick,
  });

  // FX
  const [createSpotDrawerOpen,
    setCreateSpotDrawerOpen] = useState(false);
  const [createForwardTradeDrawerOpen,
    setCreateForwardTradeDrawerOpen] = useState(false);
  const [tradeDraft,
    setTradeDraft] = useState<TradeDto>();
  const [openSummary, setOpenSummary] = useState(false);
  const [openPaymentMenu, setOpenPaymentMenu] = useState<null | HTMLElement>(null);

  const [buyCurrencies, setBuyCurrencies] = useState<string[]>([]);
  const [sellCurrencies, setSellCurrencies] = useState<string[]>([]);
  const [tradeTypes, setTradeTypes] = useState<TradeType[]>([]);
  const [tradeStatuses, setTradeStatuses] = useQueryString('tradeStatus', [TradeStatus.ACTIVE]);
  const [tradeStartDate, setTradeStartDate] = useQueryString('tradeDateFrom', undefined);
  const [tradeEndDate, setTradeEndDate] = useQueryString('tradeDateTo', undefined);
  const [valueStartDate, setValueStartDate] = useQueryString('valueDateFrom', undefined);
  const [valueEndDate, setValueEndDate] = useQueryString('valueDateTo', undefined);
  const { authorized: hasSpotRoles } = useAuthorization(
    [[UserRole.SPOT, UserRole.SPOT_INPUTTER]],
  );

  const [tradeFilters, setTradeFilters] = useState<TFilter[]>([]);
  const [filtersByField, setFiltersByField] = useState<TSearchFilterTypes>();
  const [bookTradeAction, setBookTradeAction] = useQueryString('booktrade', undefined);
  const [fxLastUpdatedTime, setFxLastUpdatedTime] = useState<string>();
  const [fxAllowed, setFxAllowed] = useState<boolean>();
  const getAccountConfiguration = async () => {
    try {
      const result = await FXTradeService.getAccountConfiguration();
      setFxAllowed(result.fxAllowed);
    } catch (e) {
      sb.trigger(e?.message || t('could_not_load_account_configuration_data'));
      logError({ action: 'Error loading account configuration', error: e });
    }
  };

  useEffect(() => {
    getAccountConfiguration();
  }, []);

  const tradeFilter = useTradeFilter(
    {
      buyCurrencies,
      sellCurrencies,
      tradeTypes,
      tradeStatuses,
      tradeStartDate,
      tradeEndDate,
      valueStartDate,
      valueEndDate,
      setBuyCurrencies,
      setSellCurrencies,
      setTradeTypes,
      setTradeStatuses,
      setTradeStartDate,
      setTradeEndDate,
      setValueStartDate,
      setValueEndDate,
      setDisableFilterBackdropClick,
    },
  );

  // Transfer
  const [openIATDrawer, setOpenIATDrawer] = useState(false);

  const [transferDebitingCurrencies, setTransferDebitingCurrencies] = useState<string[]>([]);
  const [transferFundingCurrencies, setTransferFundingCurrencies] = useState<string[]>([]);
  const [transferStatuses, setTransferStatuses] = useQueryString('transferStatus', []);
  const [transferDateStart, setTransferDateStart] = useState<string>();
  const [transferDateEnd, setTransferDateEnd] = useState<string>();

  const transferFilter = useTransferFilter({
    debitingCurrencies: transferDebitingCurrencies,
    fundingCurrencies: transferFundingCurrencies,
    transferStatuses,
    transferDateStart,
    transferDateEnd,
    setDebitingCurrencies: setTransferDebitingCurrencies,
    setFundingCurrencies: setTransferFundingCurrencies,
    setTransferStatuses,
    setTransferDateStart,
    setTransferDateEnd,
    setDisableFilterBackdropClick,
  });

  const [transferFilters, setTransferFilters] = useState<TFilter[]>([]);
  const [transferFiltersByField, setTransferFiltersByField] = useState<TTransferSearchFilterTypes>();

  // Option Trades
  const [optionTradeStartDate, setOptionTradeStartDate] = useState<string>();
  const [optionTradeEndDate, setOptionTradeEndDate] = useState<string>();

  const optionTradesFilter = useOptionTradesFilter({
    optionTradeStartDate,
    optionTradeEndDate,
    setOptionTradeStartDate,
    setOptionTradeEndDate,
    setDisableFilterBackdropClick,
  });

  const [optionsFilters, setOptionsFilters] = useState<TFilter[]>([]);
  const [optionsFiltersByField, setOptionsFiltersByField] = useState<TOptionTradesSearchFilterTypes>();

  const header = (
    <p style={{ padding: '0px 20px', margin: '8px auto' }}>
      {t('filters')}
    </p>
  );

  const menuClasses = useMenuStyles();

  const tradeFilterMenuItems = () => [
    {
      content: (
        <MultiAutoCompleteSelect
          options={tradeFilter.mapCurrencyFilterOptions(tradeFilter.buyCurrenciesFilterOptions)}
          label={t('buy_currencies')}
          noOptionsText={t('no_options')}
          placeholder={t('select_buy_currency')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{
            width: '100%',
          }}
          className={menuClasses.alphaInput}
          value={tradeFilter.buyCurrenciesFilter || []}
          onChange={(_, value) => {
            tradeFilter.setBuyCurrenciesFilter(value);
          }}
        />
      ),
      arrow: false,
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'buy-currency-options',
    },
    {
      content: (
        <MultiAutoCompleteSelect
          options={tradeFilter.mapCurrencyFilterOptions(tradeFilter.soldCurrenciesFilterOptions)}
          label={t('sell_currencies')}
          placeholder={t('select_sell_currencies')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{ width: '100%' }}
          className={menuClasses.alphaInput}
          value={tradeFilter.sellCurrenciesFilter || []}
          onChange={(_, value) => {
            tradeFilter.setSellCurrenciesFilter(value);
          }}
        />
      ),
      arrow: false,
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'sell-currency-options',
    },
    {
      content: (
        <MultiAutoCompleteSelect
          options={tradeFilter.mapTradeTypeFilterOptions(tradeFilter.typesFilterOptions)}
          label={t('trade_type')}
          placeholder={t('select_trade_type_to_filter')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{ width: '100%' }}
          className={menuClasses.alphaInput}
          value={tradeFilter.tradeTypeFilter || []}
          onChange={(_, value) => {
            tradeFilter.setTradeTypeFilter(value);
          }}
        />
      ),
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'trade-type-options',
    },
    {
      content: (
        <MultiAutoCompleteSelect
          options={tradeFilter.mapTradeStatusFilterOptions(tradeFilter.statusesFilterOptions)}
          label={t('trade_status')}
          placeholder={t('select_trade_type_to_filter')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{ width: '100%' }}
          className={menuClasses.alphaInput}
          value={tradeFilter.tradeStatusFilter || []}
          onChange={(_, value) => {
            tradeFilter.setTradeStatusFilter(value);
          }}
        />
      ),
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'trade-status-options',
    },
    {
      content: (<DateRangePicker
        label={t('trade_date')}
        labelTo={t('to^')}
        labelFrom={t('from^')}
        confirmLabel={t('confirm')}
        cancelLabel={t('cancel')}
        language={i18n.language}
        testId="trade-date-range"
        format="DD/MM/YYYY"
        onChange={tradeFilter.tradeDateChanged}
        className={menuClasses.alphaInput}
        value={tradeFilter.tradeDateFilter ? [...tradeFilter.tradeDateFilter] : [null, null]}
        staticRangeLabels={[t('today'), t('yesterday'), t('this_week'), t('last_week'), t('this_month'), t('last_month')]}
      />),
      disableClick: true,
      disableHoverEffect: true,
      key: 'trade-date-options',
    },
    {
      content: (<DateRangePicker
        label={t('value_date')}
        labelTo={t('to^')}
        labelFrom={t('from^')}
        confirmLabel={t('confirm')}
        cancelLabel={t('cancel')}
        language={i18n.language}
        testId="value-date-range"
        format="DD/MM/YYYY"
        onChange={tradeFilter.valueDateChanged}
        className={menuClasses.alphaInput}
        value={tradeFilter.valueDateFilter ? [...tradeFilter.valueDateFilter] : [null, null]}
        staticRangeLabels={[t('today'), t('yesterday'), t('this_week'), t('last_week'), t('this_month'), t('last_month')]}
      />),
      disableClick: true,
      disableHoverEffect: true,
      key: 'value-date-options',
    },
    {
      content: (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          flexGrow={1}
          alignItems="flex-end"
          gridGap={10}
        >
          <ActionButton
            style={{ background: '#F7F7F7', color: '#212529' }}
            onClick={() => {
              tradeFilter.clearAllFilters();
              logEvent({ action: 'Click Clear Trade Filters' });
            }}
          >
            {t('clear')}
          </ActionButton>
          <ActionButton
            onClick={() => {
              tradeFilter.applyChanges();
              tradeFilter.setOpen(null);
              logEvent({ action: 'Click Apply Trade Filters' });
            }}
          >
            {t('apply')}
          </ActionButton>
        </Box>
      ),
      disableHoverEffect: true,
      disableClick: true,
      arrow: false,
      key: 'action-buttons',
    },
  ];

  const optionTradesFilterMenuItems = () => [
    {
      content: (<DateRangePicker
        label={t('trade_date')}
        labelTo={t('to^')}
        labelFrom={t('from^')}
        confirmLabel={t('confirm')}
        cancelLabel={t('cancel')}
        language={i18n.language}
        testId="option-trade-date-range"
        format="DD/MM/YYYY"
        onChange={optionTradesFilter.optionTradeDateChanged}
        className={menuClasses.alphaInput}
        value={optionTradesFilter.optionTradeDateFilter ? [...optionTradesFilter.optionTradeDateFilter] : [null, null]}
        staticRangeLabels={[t('today'), t('yesterday'), t('this_week'), t('last_week'), t('this_month'), t('last_month')]}
      />),
      disableClick: true,
      disableHoverEffect: true,
      key: 'option-trade-date-options',
    },
    {
      content: (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          flexGrow={1}
          alignItems="flex-end"
          gridGap={10}
        >
          <ActionButton
            style={{ background: '#F7F7F7', color: '#212529' }}
            onClick={() => {
              optionTradesFilter.clearAllFilters();
              logEvent({ action: 'Click Clear Option Trade Filters' });
            }}
          >
            {t('clear')}
          </ActionButton>
          <ActionButton
            onClick={() => {
              optionTradesFilter.applyChanges();
              optionTradesFilter.setOpen(null);
              logEvent({ action: 'Click Apply Option Trade Filters' });
            }}
          >
            {t('apply')}
          </ActionButton>
        </Box>
      ),
      disableHoverEffect: true,
      disableClick: true,
      arrow: false,
      key: 'action-buttons',
    },
  ];

  const paymentFilterMenuItems = [{
    content: (
      <MultiAutoCompleteSelect
        options={paymentFilter.mapCurrencyFilterOptions(
          paymentFilter.paymentCurrenciesFilterOptions,
        )}
        label={t('payment_currencies')}
        placeholder={t('select_payment_currencies')}
        filterSelectedOptions
        getOptionSelected={(option, value) => option.value === value.value}
        style={{
          width: '100%',
        }}
        className={menuClasses.alphaInput}
        value={paymentFilter.paymentCurrenciesFilter || []}
        onChange={(_, value) => {
          paymentFilter.setPaymentCurrenciesFilter(value);
        }}
      />
    ),
    arrow: false,
    disableHoverEffect: true,
    disableClick: true,
    underline: false,
    key: 'buy-currency-options',
  },
  {
    content: (<DateRangePicker
      label={t('value_date')}
      labelTo={t('to^')}
      confirmLabel={t('confirm')}
      cancelLabel={t('cancel')}
      language={i18n.language}
      labelFrom={t('from^')}
      testId="value-date-range"
      format="DD/MM/YYYY"
      onChange={paymentFilter.paymentValueDateChanged}
      className={menuClasses.alphaInput}
      value={paymentFilter.paymentValueDateFilter || [null, null]}
      staticRangeLabels={[t('today'), t('yesterday'), t('this_week'), t('last_week'), t('this_month'), t('last_month')]}

    />),
    disableClick: true,
    disableHoverEffect: true,
    key: 'payment-value-date-options',
  },
  {
    content: (
      <MultiAutoCompleteSelect
        options={paymentFilter.mapPaymentStatusFilterOptions(
          paymentFilter.paymentStatusesFilterOptions,
        )}
        label={t('payment_status')}
        placeholder={t('select_payment_status_to_filter')}
        filterSelectedOptions
        getOptionSelected={(option, value) => option.value === value.value}
        style={{ width: '100%' }}
        className={menuClasses.alphaInput}
        value={paymentFilter.paymentStatusFilter || []}
        onChange={(_, value) => {
          paymentFilter.setPaymentStatusFilter(value);
        }}
      />
    ),
    disableHoverEffect: true,
    disableClick: true,
    underline: false,
    key: 'payment-status-options',
  },
  {
    content: (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        flexGrow={1}
        alignItems="flex-end"
        gridGap={10}
      >
        <ActionButton
          style={{ background: '#F7F7F7', color: '#212529' }}
          onClick={() => {
            paymentFilter.clearAllFilters();
            logEvent({ action: 'Click Clear Payment Filters' });
          }}
        >
          {t('clear')}
        </ActionButton>
        <ActionButton
          onClick={() => {
            paymentFilter.applyChanges();
            paymentFilter.setPaymentFilterOpen(null);
            logEvent({ action: 'Click Apply Payment Filters' });
          }}
        >
          {t('apply')}
        </ActionButton>
      </Box>
    ),
    disableHoverEffect: true,
    disableClick: true,
    arrow: false,
    key: 'action-buttons',
  }];

  const transferFilterMenuItems = () => [
    {
      content: (
        <MultiAutoCompleteSelect
          options={paymentFilter.mapCurrencyFilterOptions(
            transferFilter.fundingCurrenciesFilterOptions,
          )}
          label={t('from_currencies')}
          placeholder={t('select_from_currencies')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{
            width: '100%',
          }}
          className={menuClasses.alphaInput}
          value={transferFilter.fundingCurrenciesFilter || []}
          onChange={(_, value) => {
            transferFilter.setFundingCurrenciesFilter(value);
          }}
        />
      ),
      arrow: false,
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'transfer-from-currency-options',
    },
    {
      content: (
        <MultiAutoCompleteSelect
          options={transferFilter.mapCurrencyFilterOptions(
            transferFilter.debitingCurrenciesFilterOptions,
          )}
          label={t('to_currencies')}
          placeholder={t('select_to_currencies')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{
            width: '100%',
          }}
          className={menuClasses.alphaInput}
          value={transferFilter.debitingCurrenciesFilter || []}
          onChange={(_, value) => {
            transferFilter.setDebitingCurrenciesFilter(value);
          }}
        />
      ),
      arrow: false,
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'transfer-to-currency-options',
    },
    {
      content: (
        <MultiAutoCompleteSelect
          options={transferFilter.mapTransferStatusFilterOptions(transferFilter.transferStatusFilterOptions)}
          label={t('transfer_status')}
          placeholder={t('select_transfer_status_to_fiter')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{ width: '100%' }}
          className={menuClasses.alphaInput}
          value={transferFilter.transferStatusFilter || []}
          onChange={(_, value) => {
            transferFilter.setTransferStatusFilter(value);
          }}
        />
      ),
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'transfer-status-options',
    },
    {
      content: (<DateRangePicker
        label={t('transfer_date')}
        labelTo={t('to^')}
        confirmLabel={t('confirm')}
        cancelLabel={t('cancel')}
        language={i18n.language}
        labelFrom={t('from^')}
        testId="transfer-date-range"
        format="DD/MM/YYYY"
        onChange={transferFilter.valueDateChanged}
        className={menuClasses.alphaInput}
        value={transferFilter.valueDateFilter ? [...transferFilter.valueDateFilter] : [null, null]}
        staticRangeLabels={[t('today'), t('yesterday'), t('this_week'), t('last_week'), t('this_month'), t('last_month')]}
      />),
      disableClick: true,
      disableHoverEffect: true,
      key: 'trade-date-options',
    },
    {
      content: (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          flexGrow={1}
          alignItems="flex-end"
          gridGap={10}
        >
          <ActionButton
            style={{ background: '#F7F7F7', color: '#212529' }}
            onClick={() => {
              transferFilter.clearAllFilters();
              logEvent({ action: 'Click Clear Transfer Filters' });
            }}
          >
            {t('clear')}
          </ActionButton>
          <ActionButton
            onClick={() => {
              transferFilter.applyChanges();
              transferFilter.setOpen(null);
              logEvent({ action: 'Click Apply Transfer Filters' });
            }}
          >
            {t('apply')}
          </ActionButton>
        </Box>
      ),
      disableHoverEffect: true,
      disableClick: true,
      arrow: false,
      underline: false,
      key: 'action-buttons',
    },
  ];

  const filterRemoveHandlers = {
    buyCurrency: (value: string) => {
      setBuyCurrencies(buyCurrencies.filter(
        (item) => item !== value,
      ));
    },
    sellCurrency: (value: string) => {
      setSellCurrencies(sellCurrencies.filter(
        (item) => item !== value,
      ));
    },
    tradeType: (value: string) => {
      setTradeTypes(tradeTypes.filter(
        (item) => item !== value,
      ));
    },
    tradeStatus: (value: string) => {
      setTradeStatuses(tradeStatuses.filter(
        (item: TradeStatus) => item !== value,
      ));
    },
    tradeStartDate: () => { setTradeStartDate(''); },
    tradeEndDate: () => { setTradeEndDate(''); },
    valueStartDate: () => { setValueStartDate(''); },
    valueEndDate: () => { setValueEndDate(''); },

    paymentCurrency: (value: string) => {
      setPaymentCurrencies(paymentCurrencies.filter(
        (item) => item !== value,
      ));
    },
    paymentStatus: (value: string) => {
      setPaymentStatuses(paymentStatuses.filter(
        (item: string) => item !== value,
      ));
    },
    paymentValueStartDate: () => { setPaymentValueStartDate(''); },
    paymentValueEndDate: () => { setPaymentValueEndDate(''); },
    transferDebitingCurrency: (value: string) => {
      setTransferDebitingCurrencies(transferDebitingCurrencies.filter(
        (item) => item !== value,
      ));
    },
    transferFundingCurrency: (value: string) => {
      setTransferFundingCurrencies(transferFundingCurrencies.filter(
        (item) => item !== value,
      ));
    },
    transferStatus: (value: string) => {
      setTransferStatuses(transferStatuses.filter(
        (item: string) => item !== value,
      ));
    },
    transferValueStartDate: () => { setTransferDateStart(''); },
    transferValueEndDate: () => { setTransferDateEnd(''); },
    optionTradeStartDate: () => { setOptionTradeStartDate(''); },
    optionTradeEndDate: () => { setOptionTradeEndDate(''); },
  };

  const handleRemoveFilterItem = (filterItem: TFilter): void => {
    const { field, value } = filterItem;
    type TField = keyof typeof filterRemoveHandlers;
    const handler = filterRemoveHandlers[field as TField];
    if (handler) { handler(value); }
  };

  const fxFilterTitle = (): TText => {
    const filterCount = tradeFilters.length;
    return filterCount > 0 ? `${t('filters')} (${filterCount})` : t('filters');
  };

  const paymentFilterTitle = (): TText => {
    const filterCount = paymentFilters.length;
    return filterCount > 0 ? `${t('filters')} (${filterCount})` : t('filters');
  };

  const transferFilterTitle = (): TText => {
    const filterCount = transferFilters.length;
    return filterCount > 0 ? `${t('filters')} (${filterCount})` : t('filters');
  };

  const paymentFileMenuOptions = [
    {
      content: t('create_manual_payments'),
      icon: faList,
      onClick: () => {
        setCreatePaymentsDrawerOpen(true);
        setOpenPaymentMenu(null);
        logEvent({ action: 'Click create manual payment' });
      },
      disableHoverEffect: true,
      disableClick: true,
      underline: true,
    },
    {
      content: t('payment_upload'),
      icon: faArrowUp,
      onClick: () => {
        logEvent({ action: 'Click Payment Upload' });
        browserHistory.push(routes.transactions.paymentUpload);
      },
    },
  ];

  // eslint-disable-next-line max-lines-per-function
  const rightAlignedCta = () => {
    if (tabIndex === 0) {
      return (
        <Box display="flex" flexDirection="row" gridGap={10} className={menuClasses.rightCTA}>
          <APFilterMenu
            open={Boolean(tradeFilter.open)}
            anchorEl={tradeFilter.open}
            setOpen={tradeFilter.setOpen}
            disableBackdropClick={disableFilterBackdropClick}
            header={header}
            // @ts-ignore
            icon={faSlidersH}
            buttonTitle={fxFilterTitle()}
            key="filter-button"
            menuProps={{
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
              className: menuClasses.menuCustom,
            }}
          >
            {tradeFilterMenuItems().map((item) => (
              <MenuDropdownItem
                key={item.key}
                setOpen={tradeFilter.setOpen}
              >
                {item}
              </MenuDropdownItem>
            ))}
          </APFilterMenu>

          {!isEMoneyDisabled() && fxAllowed && (
            <Box className={classes.spotButtonWrapper}>
              <Authorization requiredRoles={[[UserRole.SPOT, UserRole.SPOT_INPUTTER]]} key="auth-button">
                {isForwardInputter
                  ? (
                    <APMenu
                      hideIcon={false}
                      buttonProps={{ disabled: isFxMarketClosed() }}
                      open={Boolean(openSpotMenu)}
                      anchorEl={openSpotMenu}
                      setOpen={setOpenSpotMenu}
                      disableBackdropClick={false}
                      buttonTitle={(
                        <>
                          <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '8px', height: '12px', width: '12px' }} />
                          {t('new_trade')}
                        </>
                      )}
                    >
                      {spotTradeMenuOptions.concat(forwardTradeOption).map((
                        item,
                      ) => <MenuDropdownItem setOpen={setOpenSpotMenu}>{item}</MenuDropdownItem>)}
                    </APMenu>
                  ) : (
                    <ActionButton
                      disabled={isFxMarketClosed()}
                      onClick={() => {
                        setCreateSpotDrawerOpen(true);
                      }}
                      size="medium"
                    >
                      {t('new_spot_trade')}
                    </ActionButton>
                  )}
              </Authorization>
            </Box>
          )}

          {!isEMoneyDisabled() && fxAllowed && isForwardInputter && !hasSpotRoles && (
            <Box className={classes.spotButtonWrapper}>
              <Authorization requiredRoles={[[UserRole.FORWARD_INPUTTER]]} key="auth-button">
                <ActionButton
                  disabled={isFxMarketClosed()}
                  onClick={() => {
                    setCreateForwardTradeDrawerOpen(true);
                  }}
                  size="medium"
                >
                  {t('new_forward_trade')}
                </ActionButton>
              </Authorization>
            </Box>
          )}

          {(isEMoneyDisabled() && fxAllowed && (
            <Authorization requiredRoles={[[UserRole.SPOT]]} key="auth-button">
              <APTooltip
                placement="bottom-end"
                arrow
                title={isFxMarketClosed() ? t('fx_execution_is_not_available_at_this_time1') : ''}
              >
                <div>
                  <ActionButton
                    size="medium"
                    testId="create-trade"
                    disabled={isFxMarketClosed()}
                    onClick={() => {
                      setFirstPartyFlow(true);
                      setCreateSpotDrawerOpen(true);
                    }}
                  >
                    {t('new_first_party_spot')}
                  </ActionButton>
                </div>
              </APTooltip>
            </Authorization>
          ))}

        </Box>
      );
    }

    if (tabIndex === 3) {
      return (
        <Box display="flex" flexDirection="row" gridGap={10} className={menuClasses.rightCTA}>
          <>
            <APFilterMenu
              open={Boolean(transferFilter.open)}
              anchorEl={transferFilter.open}
              setOpen={transferFilter.setOpen}
              disableBackdropClick={disableFilterBackdropClick}
              header={header}
              // @ts-ignore
              icon={faSlidersH}
              buttonTitle={transferFilterTitle()}
              key="transfer-filter-button"
              menuProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                className: menuClasses.menuCustom,
              }}
            >
              {transferFilterMenuItems().map((item) => (
                <MenuDropdownItem
                  key={item.key}
                  setOpen={transferFilter.setOpen}
                >
                  {item}
                </MenuDropdownItem>
              ))}
            </APFilterMenu>
            <Authorization requiredRoles={[[UserRole.IAT_INPUTTER]]}>
              <ActionButton
                size="medium"
                testId="create-transfer"
                onClick={() => setOpenIATDrawer(true)}
              >
                {t('inter_account_transfer')}
              </ActionButton>
            </Authorization>
          </>
        </Box>
      );
    }

    if (tabIndex === 2) {
      return (
        <Box display="flex" flexDirection="row" gridGap={10} className={menuClasses.rightCTA}>
          <APFilterMenu
            open={Boolean(paymentFilter.paymentFilterOpen)}
            anchorEl={paymentFilter.paymentFilterOpen}
            setOpen={paymentFilter.setPaymentFilterOpen}
            disableBackdropClick={disableFilterBackdropClick}
            header={header}
            // @ts-ignore
            icon={faSlidersH}
            buttonTitle={paymentFilterTitle()}
            key="filter-button"
            menuProps={{
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
              className: menuClasses.menuCustom,
            }}
          >
            {paymentFilterMenuItems.map((item) => (
              <MenuDropdownItem
                key={item.key}
                setOpen={paymentFilter.setPaymentFilterOpen}
              >
                {item}
              </MenuDropdownItem>
            ))}
          </APFilterMenu>

          {isPaymentFileInputter ? (
            <Box className={menuClasses.paymentCTA}>
              <APMenu
                open={Boolean(openPaymentMenu)}
                anchorEl={openPaymentMenu}
                setOpen={setOpenPaymentMenu}
                disableBackdropClick={false}
                hideIcon
                buttonTitle={t('new_payment')}
              >
                {paymentFileMenuOptions.map((
                  item,
                ) => <MenuDropdownItem setOpen={setOpenPaymentMenu}>{item}</MenuDropdownItem>)}
              </APMenu>
            </Box>
          ) : (
            <Authorization requiredRoles={[[UserRole.PAYMENTS_INPUTTER]]}>
              <ActionButton
                size="medium"
                testId="create-payment"
                disabled={false}
                onClick={() => setCreatePaymentsDrawerOpen(true)}
              >
                {t('new_payment')}
              </ActionButton>
            </Authorization>
          )}
        </Box>
      );
    }

    if (tabIndex === 1) {
      return (
        <Box display="flex" flexDirection="row" gridGap={10} className={menuClasses.rightCTA}>
          <APFilterMenu
            open={Boolean(optionTradesFilter.open)}
            anchorEl={optionTradesFilter.open}
            setOpen={optionTradesFilter.setOpen}
            disableBackdropClick={disableFilterBackdropClick}
            header={header}
            // @ts-ignore
            icon={faSlidersH}
            buttonTitle={paymentFilterTitle()}
            key="filter-button"
            menuProps={{
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
              className: menuClasses.menuCustom,
            }}
          >
            {optionTradesFilterMenuItems().map((item) => (
              <MenuDropdownItem
                key={item.key}
                setOpen={optionTradesFilter.setOpen}
              >
                {item}
              </MenuDropdownItem>
            ))}
          </APFilterMenu>
        </Box>
      );
    }
  };

  const onTabChange = (index: number) => {
    setTabIndex(index);
  };

  const parseFxFilters = (buyCurrenciesSelected: string[],
    sellCurrenciesSelected: string[],
    tradeTypesSelected: TradeType[],
    tradeStatusesSelected: TradeStatus[],
    tradeStartDateSelected: string | undefined,
    tradeEndDateSelected: string | undefined,
    valueStartDateSelected: string | undefined,
    valueEndDateSelected: string | undefined) => {
    const result: TFilter[] = [];

    result.push(...buyCurrenciesSelected.map((buyCurrency) => ({ field: 'buyCurrency', value: buyCurrency as string })));
    result.push(...sellCurrenciesSelected.map((buyCurrency) => ({ field: 'sellCurrency', value: buyCurrency as string })));
    result.push(...tradeStatusesSelected.map((tradeStatus) => ({ field: 'tradeStatus', value: tradeStatus as string })));
    result.push(...tradeTypesSelected.map((tradeType) => ({ field: 'tradeType', value: tradeType as string })));
    if (tradeStartDateSelected) { result.push({ field: 'tradeStartDate', value: tradeStartDateSelected }); }
    if (tradeEndDateSelected) { result.push({ field: 'tradeEndDate', value: tradeEndDateSelected }); }
    if (valueStartDateSelected) { result.push({ field: 'valueStartDate', value: valueStartDateSelected }); }
    if (valueEndDateSelected) { result.push({ field: 'valueEndDate', value: valueEndDateSelected }); }

    return result;
  };

  const parsePaymentFilters = (paymentCurrenciesSelected: string[],
    paymentStatusesSelected: PaymentStatus[],
    paymentValueStartDateSelected: string | undefined,
    paymentValueEndDateSelected: string | undefined) => {
    const result: TFilter[] = [];

    result.push(...paymentCurrenciesSelected.map((paymentCurrency) => ({ field: 'paymentCurrency', value: paymentCurrency as string })));
    result.push(...paymentStatusesSelected.map((paymentStatus) => ({ field: 'paymentStatus', value: paymentStatus as string })));
    if (paymentValueStartDateSelected) { result.push({ field: 'paymentValueStartDate', value: paymentValueStartDateSelected }); }
    if (paymentValueEndDateSelected) { result.push({ field: 'paymentValueEndDate', value: paymentValueEndDateSelected }); }

    return result;
  };

  const handleApprovePayments = (payments: PaymentDto[]): void => {
    setPaymentsToApprove(payments);
    setApprovalDrawerOpen(true);
  };

  const handleRejectPayments = (payments: PaymentDto[]): void => {
    setPaymentsToReject(payments);
    setRejectModalOpen(true);
  };

  const parseTransferFilters = (
    transferDebitingCurrenciesSelected: string[],
    transferFundingCurrenciesSelected: string[],
    transferStatusesSelected: PaymentStatus[],
    transferValueStartDateSelected: string | undefined,
    transferValueEndDateSelected: string | undefined,
  ) => {
    const result: TFilter[] = [];

    result.push(...transferDebitingCurrenciesSelected.map((debitingCurrency) => ({ field: 'transferDebitingCurrency', value: debitingCurrency as string })));
    result.push(...transferFundingCurrenciesSelected.map((fundingCurrency) => ({ field: 'transferFundingCurrency', value: fundingCurrency as string })));
    result.push(...transferStatusesSelected.map((transferStatus) => ({ field: 'transferStatus', value: transferStatus as string })));
    if (transferValueStartDateSelected) { result.push({ field: 'transferValueStartDate', value: transferValueStartDateSelected }); }
    if (transferValueEndDateSelected) { result.push({ field: 'transferValueEndDate', value: transferValueEndDateSelected }); }

    return result;
  };

  const parseOptionTradesFilters = (
    optionTradeStartDateSelected: string | undefined,
    optionTradeEndDateSelected: string | undefined,
  ) => {
    const result: TFilter[] = [];
    if (optionTradeStartDateSelected) { result.push({ field: 'optionTradeStartDate', value: optionTradeStartDateSelected }); }
    if (optionTradeEndDateSelected) { result.push({ field: 'optionTradeEndDate', value: optionTradeEndDateSelected }); }
    return result;
  };

  const spotTradeMenuOptions = [
    {
      content: t('new_spot_trade'),
      icon: faArrowDown,
      onClick: () => {
        setFirstPartyFlow(false);
        setCreateSpotDrawerOpen(true);
      },
    },
    // {
    //   content: t('new_first_party_spot'),
    //   icon: faArrowDown,
    //   onClick: () => {
    //     setFirstPartyFlow(true);
    //     setCreateSpotDrawerOpen(true);
    //   },
    // },
  ];

  const forwardTradeOption = [
    {
      content: t('new_forward_trade'),
      icon: faCalendarAlt,
      onClick: () => {
        setCreateForwardTradeDrawerOpen(true);
      },
    },
  ];

  useEffect(() => {
    const tradeStatusesFiltered = tradeStatuses.filter(
      (item: TradeStatus) => (Object.values(TradeStatus).includes(item)),
    );

    // For display
    setTradeFilters(parseFxFilters(buyCurrencies,
      sellCurrencies,
      tradeTypes,
      tradeStatusesFiltered,
      tradeStartDate,
      tradeEndDate,
      valueStartDate,
      valueEndDate));

    // For searching
    setFiltersByField({
      buyCurrencies,
      sellCurrencies,
      tradeTypes,
      tradeStatuses: tradeStatusesFiltered,
      tradeStartDate,
      tradeEndDate,
      valueStartDate,
      valueEndDate,
    });
  }, [buyCurrencies,
    sellCurrencies,
    tradeTypes,
    tradeStatuses,
    tradeStartDate,
    tradeEndDate,
    valueStartDate,
    valueEndDate]);

  useEffect(() => {
    setPaymentFilters(parsePaymentFilters(paymentCurrencies,
      paymentStatuses,
      paymentValueStartDate,
      paymentValueEndDate));

    setPaymentFiltersByField({
      paymentCurrencies,
      paymentStatuses,
      paymentValueStartDate,
      paymentValueEndDate,
    });
  }, [
    paymentCurrencies,
    paymentStatuses,
    paymentValueStartDate,
    paymentValueEndDate,
  ]);

  useEffect(() => {
    setTransferFilters(parseTransferFilters(
      transferDebitingCurrencies,
      transferFundingCurrencies,
      transferStatuses,
      transferDateStart,
      transferDateEnd,
    ));
    setTransferFiltersByField({
      debitingCurrencies: transferDebitingCurrencies,
      fundingCurrencies: transferFundingCurrencies,
      statuses: transferStatuses,
      valueDateStart: transferDateStart,
      valueDateEnd: transferDateEnd,
    });
  }, [
    transferDateStart,
    transferDateEnd,
    transferDebitingCurrencies,
    transferFundingCurrencies,
    transferStatuses,
  ]);

  useEffect(() => {
    setOptionsFilters(parseOptionTradesFilters(
      optionTradeStartDate,
      optionTradeEndDate,
    ));
    setOptionsFiltersByField({
      startDate: optionTradeStartDate,
      endDate: optionTradeEndDate,
    });
  }, [
    optionTradeStartDate,
    optionTradeEndDate,
  ]);

  useEffect(() => {
    setPaymentsToReject(multiSelectedPayments);
    setPaymentsToApprove(multiSelectedPayments);
  }, [multiSelectedPayments]);

  useEffect(() => {
    const openSpotTradeDrawer = async (tradeId: string) => {
      try {
        const submittedTrade = await FXTradeService.getTradeData(tradeId);
        if (submittedTrade && submittedTrade.status === TradeStatus.SUBMITTED) {
          setTradeDraft(submittedTrade);
          setCreateSpotDrawerOpen(true);
        }
      } catch (error) {
        sb.trigger(error?.message || t('there_is_an_error_loading_trade_information'), 'error');
        logError({ action: 'Error loading trade information', error });
      }
    };

    if (bookTradeAction) {
      openSpotTradeDrawer(bookTradeAction);
    }
  }, [bookTradeAction]);

  const reloadData = () => {
    // trigger reload
    setTransferFiltersByField({ ...transferFiltersByField } as TTransferSearchFilterTypes);
  };

  return (
    <APMainLayout
      height="165px"
      title={t('transactions')}
      breadCrumbs={[{ text: t('home'), url: homePageUrl }]}
      sticky
      pageTabs={<Tabs onTabChange={onTabChange} />}
      rightAlignedCta={rightAlignedCta()}
    >
      <div style={{
        paddingTop: '12px',
        backgroundColor: '#fafafa',
        minHeight: 'calc(100vh - 310px)',
        paddingBottom: '30px',
      }}
      >
        <Switch>
          <Route path={routes.transactions.fxtrades}>
            <Card>
              <div className={classes.headerRow}>
                <Typography className={classes.tableHeading}>{t('summary')}</Typography>
                <IconButton
                  onClick={() => setOpenSummary(!openSummary)}
                  style={{ height: '50%', borderRadius: 0 }}
                >
                  <FontAwesomeIcon
                    className={clsx(classes.icon, { up: openSummary })}
                    icon={faChevronDown as IconProp}
                    size="lg"
                  />
                </IconButton>
              </div>
              <Typography className={classes.tableSubHeading}>
                {t('summary_view_of_open_positions_by_currency_pair')}
              </Typography>
              <Collapse in={openSummary}>
                <FxSummaryTable
                  setOpenSummary={setOpenSummary}
                  testId="fx-summary-table"
                  emptyTitle={t('no_fx_trades')}
                  emptySubtitle={t('you_currently_do_not_have_any_fx_trades')}
                />
              </Collapse>
            </Card>
            <Card>
              <Typography className={classes.tableHeading}>{t('fx_trades')}</Typography>
              <FxTradeTable
                testId="fx-trade-table"
                filters={tradeFilters}
                filtersByField={filtersByField}
                handleRemoveFilterItem={handleRemoveFilterItem}
                emptyTitle={t('no_fx_trades')}
                emptySubtitle={t('you_currently_do_not_have_any_matching_fx_trades')}
                lastUpdatedTime={fxLastUpdatedTime}
              />
            </Card>
          </Route>
          <Route path={routes.transactions.transfer}>
            <Card>
              <TransferTable
                testId="transfer-table"
                emptyTitle={t('no_internal_account_transfers')}
                emptySubtitle={t('you_currently_do_not_have_any_matching_transfers')}
                filters={transferFilters}
                filtersByField={transferFiltersByField}
                handleRemoveFilterItem={handleRemoveFilterItem}
              />
              <IATDrawerContainer
                open={openIATDrawer}
                onClose={() => setOpenIATDrawer(false)}
                onSubmittedTransfer={() => {
                  setTimeout(() => {
                    reloadData();
                  }, 1000);
                }}
              />
            </Card>
          </Route>
          <Route path={routes.transactions.optionTrades}>
            <Card>
              <OptionTradesTable
                testId="option-trades-table"
                filters={optionsFilters}
                filtersByField={optionsFiltersByField}
                handleRemoveFilterItem={handleRemoveFilterItem}
                emptyTitle={t('no_option_trades')}
                emptySubtitle={t('you_currently_do_not_have_any_matching_option_trades')}
              />
            </Card>
          </Route>
          <Route path={routes.transactions.base}>
            <Card>
              <PaymentTableContainer
                createPaymentOpen={createPaymentsDrawerOpen}
                setCreatePaymentOpen={setCreatePaymentsDrawerOpen}
                selectedPayment={selectedPayment}
                setSelectedPayment={setSelectedPayment}
                multiSelectedPayments={multiSelectedPayments}
                setMultiSelectedPayments={setMultiSelectedPayments}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                selectedPaymentBeneficiary={selectedPaymentBeneficiaryId}
                setSelectedPaymentBeneficiary={setSelectedPaymentBeneficiaryId}
                handleOpenApprovalDrawer={() => setApprovalDrawerOpen(true)}
                handleRejectModalOpen={() => setRejectModalOpen(true)}
                handleApprovePayments={handleApprovePayments}
                handleRejectPayments={handleRejectPayments}
                rejectedPaymentIds={rejectedPaymentIds}
                filters={paymentFilters}
                filtersByField={paymentFiltersByField}
                handleRemoveFilterItem={handleRemoveFilterItem}
              />
            </Card>
          </Route>
        </Switch>
        <CreatePaymentDrawer
          open={createPaymentsDrawerOpen}
          onClose={() => {
            setCreatePaymentsDrawerOpen(false);
          }}
          triggerSpotOpen={{
            setOpen: setCreateSpotDrawerOpen,
            setTradeDraft,
          }}
        />
        <CreateSpotDrawer
          firstPartyFlow={firstPartyFlow}
          open={createSpotDrawerOpen}
          onClose={() => {
            setCreateSpotDrawerOpen(false);
          }}
          tradeDraft={tradeDraft}
          setTradeDraft={setTradeDraft}
          onTradeBooked={() => {
            setFxLastUpdatedTime(Date.now().toString());
          }}
          heading={firstPartyFlow ? t('first_party_spot') : t('new_spot_trade')}
        />
        <CreateForwardTradeDrawer
          open={createForwardTradeDrawerOpen}
          onClose={() => {
            setCreateForwardTradeDrawerOpen(false);
          }}
          onTradeBooked={() => {
            setFxLastUpdatedTime(Date.now().toString());
          }}
          tradeDraft={tradeDraft}
          setTradeDraft={setTradeDraft}
          heading={t('new_forward_trade')}
        />
        <PaymentInfoDrawer
          paymentDrawerOpen={Boolean(selectedPayment)}
          setPaymentDrawerOpen={setSelectedPayment}
          selectedPayment={selectedPayment}
          sharedStyles={classes}
          testIds={
            {
              paymentDrawerBeneficiaryTable: 'payment-info-drawer-bene',
              paymentDrawerPaymentTable: 'payment-info-drawer-payment',
            }
          }
        />
        <BeneficiaryInfoDrawer
          paymentSummaryBeneficiaryId={selectedPaymentBeneficiaryId}
          setPaymentSummaryBeneficiaryId={setSelectedPaymentBeneficiaryId}
          onClose={() => { setSelectedPaymentBeneficiaryId(undefined); }}
          open={Boolean(selectedPaymentBeneficiaryId)}
        />
        {
          (
            paymentsToApprove.length > 0
            && paymentsToApprove[0].status === PaymentStatus.SUBMITTED)
          && (
            approvalDrawerOpen
              ? (
                <ApprovalDrawer
                  open={approvalDrawerOpen}
                  onClose={() => {
                    setApprovalDrawerOpen(false);
                    setSelectedIds([]);
                  }}
                  selectedPayments={paymentsToApprove}
                />
              ) : null
          )
        }
        {
          (
            paymentsToReject.length > 0
            && paymentsToReject[0].status === PaymentStatus.SUBMITTED)
          && (
            <RejectPaymentModal
              open={rejectModalOpen}
              handleClose={() => {
                setRejectModalOpen(false);
                setSelectedIds([]);
              }}
              payments={paymentsToReject}
              handleRejected={(rejectedIds) => {
                setRejectedPaymentIds(rejectedIds);
              }}
            />
          )
        }
      </div>
    </APMainLayout>
  );
};

export default Dashboard;
