import React, { useEffect } from 'react';
import clsx from 'clsx';
import Alert from 'components/Alert';
import { FormikProps } from 'formik';
import moment from 'moment';
import { formatCurrency } from 'utils/currency.helpers';
import mapTradeFundingMethodToFriendlyName from 'utils/fxTrades/mapTradeFundingMethodToFriendlyName';
import t from 'utils/translationHelper';

import { TradeFundingMethod } from '@alpha/fx-dtos';
import {
  Box, Divider, FormControlLabel, Radio,
  RadioGroup, Typography,
} from '@alpha/ui-lib/ui/external';
import { StyledKeyValueTable, TKeyValue } from '@alpha/ui-lib/ui/table';

import { TSpotFXRequestForm } from '../../formData';
import { useStyles } from '../index.styles';

interface IProps {
  form: FormikProps<TSpotFXRequestForm>;
  padAllowed: boolean;
  show: boolean;
  editable: boolean;
  insufficientFunds: boolean;
  isTransfer: boolean;
  firstPartyFlow: boolean;
  validatedTrade?: boolean;
}

const FundingMethod: React.FC<IProps> = ({
  form,
  padAllowed = false,
  show = false,
  editable = true,
  insufficientFunds,
  isTransfer,
  firstPartyFlow,
  validatedTrade,
}) => {
  const styles = useStyles();

  useEffect(() => {
    if (firstPartyFlow) {
      form.setFieldValue('fundingMethod', TradeFundingMethod.WIRE_TRANSFER);
    }
  }, [firstPartyFlow]);

  const tableData = (fundingMethod: TradeFundingMethod): TKeyValue[] => {
    const data: TKeyValue[] = [];

    if (!editable) {
      data.push({
        key: t('value_date'),
        value: moment(form.values.valueDate).format('DD/MM/YYYY'),
      });
      data.push({
        key: t('funding_method'),
        value: t(mapTradeFundingMethodToFriendlyName(form.values.fundingMethod)),
      });
    }

    if (fundingMethod === TradeFundingMethod.CURRENCY_ACCOUNT_BALANCE && editable) {
      data.push({
        key: `${t('account_name')}:`,
        value: form.values.selectedSellCurrencyAccount?.friendlyName || form.values.selectedSellCurrencyAccount?.accountName || '-',
      },
      {
        key: `${t('account_balance')}:`,
        value: `${formatCurrency(form.values.sellCurrencyCode, form.values.selectedSellCurrencyAccount?.clearedBalance, false)}` || '-',
      });
    }

    return data;
  };

  if (show) {
    return (
      <Box className={styles.card} style={{ marginTop: '25px' }}>
        {editable && !firstPartyFlow && (
          <Box style={{ padding: '20px 20px 0px 20px' }}>
            <Typography style={{ fontWeight: '600', marginBottom: '10px', fontSize: '16px' }}>{t('funding_method')}</Typography>
            <RadioGroup
              row
              value={form.values.fundingMethod}
              onChange={(e) => form.setFieldValue('fundingMethod', e.target.value)}
              style={{
                flexDirection: 'column',
              }}
              id="spot-funding-method"
            >
              {!isTransfer && (
                <FormControlLabel
                  value={TradeFundingMethod.CURRENCY_ACCOUNT_BALANCE}
                  control={<Radio color="primary" />}
                  label={t('use_funds_on_account')}
                  data-testid="funds-on-account-drawdown"
                  name="spot-funding-method-account"
                />
              )}

              <FormControlLabel
                value={TradeFundingMethod.WIRE_TRANSFER}
                control={<Radio color="primary" />}
                label={t('wire_transfer_funds')}
                data-testid="wire-transfer-drawdown"
                name="spot-funding-method-wired"
              />

              {padAllowed && !isTransfer && (
                <FormControlLabel
                  value={TradeFundingMethod.PAD}
                  control={<Radio color="primary" />}
                  label={t('settle_with_pad')}
                  data-testid="pad-drawdown"
                  name="spot-funding-method-pad"
                />
              )}
            </RadioGroup>
          </Box>
        )}

        {tableData(form.values.fundingMethod).length > 0 && !validatedTrade && (
          <Box>
            <Divider />
            <Box style={{ padding: '20px', backgroundColor: '#f7f7f7' }}>
              <StyledKeyValueTable
                testId="trade-funding-method-table"
                data={tableData(form.values.fundingMethod)}
                className={clsx({
                  [styles.insufficientFundsTable]: insufficientFunds
                    && form.values.fundingMethod === TradeFundingMethod.CURRENCY_ACCOUNT_BALANCE
                    && editable,
                  [styles.sufficientFundsTable]: !insufficientFunds
                    && form.values.fundingMethod === TradeFundingMethod.CURRENCY_ACCOUNT_BALANCE
                    && editable,
                })}
              />
            </Box>
          </Box>
        )}

        {validatedTrade && (
          <Box className={styles.accountInfoContainer}>
            <StyledKeyValueTable
              testId="trade-funding-method-table"
              data={tableData(form.values.fundingMethod)}
              className={clsx({
                [styles.insufficientFundsTable]: insufficientFunds
                  && editable,
                [styles.sufficientFundsTable]: !insufficientFunds
                  && editable,
              })}
            />
          </Box>
        )}

        {form.values.fundingMethod === TradeFundingMethod.WIRE_TRANSFER
          && !firstPartyFlow && editable && (
          <div style={{ width: '100%', padding: '20px' }}>
            <Alert
              className={styles.alert}
              variant="info"
              text={t('details_of_where_to_send_the_settlement_will_be_displayed_on_the_final_screen')}
            />
          </div>
        )}

        {form.values.fundingMethod === TradeFundingMethod.CURRENCY_ACCOUNT_BALANCE
          && insufficientFunds && (
            <div style={{ width: '100%' }}>
              <Alert
                variant="warning"
                className={styles.insufficientFundsAlert}
                text={t('insufficient_funds')}
                subText={t('unable_to_select_this_method_due_to_insufficient_funds_on_your_account')}
              />
            </div>
        )}

        {form.values.fundingMethod === TradeFundingMethod.PAD
          && padAllowed && editable && (
          <div style={{ width: '10px auto', padding: '20px' }}>
            <Alert
              variant="info"
              className={styles.alert}
              text={t('please_note_that_by_submitting_you_are_authorising_alpha_fx_to_collect_funds')}
            />
          </div>
        )}
      </Box>
    );
  }

  return <> </>;
};

export default FundingMethod;
