import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  () => ({
    drawerOverlay: {
      width: '35%',
      height: '100%',
      position: 'fixed',
      right: 0,
      top: 0,
      backgroundColor: 'rgba(255,255,255,0.7)',
    },
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      '& h6': {
        marginTop: '16px',
      },
    },
  }),
  { name: 'DrawerBackdrop' },
);

export default useStyles;
