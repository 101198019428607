import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography }) => ({
  trackerContainer: {
    padding: '0px 12px',
  },
  detailContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '5px',
  },
  textStyle: {
    fontSize: 13,
    fontWeight: 400,
    lineHeigt: '15px',
    fontFamily: typography.fontFamilySecondary,
  },
  statsContainer: {
    backgroundColor: '#F4F4F4',
    padding: '24px',
    borderRadius: '10px',
  },
  standardContainer: {
    margin: '0px 12px',
  },
  amountToAssignText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  amountToAssignContainer: {
    padding: '20px 0px 10px',
  },
}), { name: 'TradePayments' });

export default useStyles;
