import React, {
  useCallback, useEffect, useState,
} from 'react';
import clsx from 'clsx';
import Alert from 'components/Alert';
import InputAndLabel from 'components/Inputs/Beneficiary/InputAndLabel';
import Dropdown from 'components/InterAccountTransfer/Dropdown/Dropdown';
import PaymentContext from 'components/Molecules/Payments/Beneficiaries/AddPayment/paymentContext';
import CreateBeneficiary from 'domain/Beneficiaries/CreateBeneficiary';
import BeneficiaryDropdownContainer from
  'domain/Transactions/ManualPayments/Body/PaymentType/ManualPayment/BeneficiaryDropdown/BeneficiaryDropdownContainer';
import { FormikProps } from 'formik';
import Authorization from 'hocs/Authorization';
import useSwitchAccount from 'hooks/useSwitchAccount';
import i18n from 'i18n/config';
import { debounce } from 'lodash';
import { UserRole } from 'models/user';
import { Moment } from 'moment';
import NumberFormat from 'react-number-format';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import PaymentsService from 'services/Payments/payments.service';
import StatementsService from 'services/Statements/statements.service';
import { filterByCurrencyAccountToData } from 'utils/mapDropdown';
import timer from 'utils/timer';
import t from 'utils/translationHelper';

import { UserAcknowledgmentRequest, UserAcknowledgmentType } from '@alpha/auth-dtos';
import {
  ExecuteTradeRequest,
  ExecuteTradeResultDto,
  TradeDto,
  TradeFundingMethod,
  TradeRequest,
  TradeSubmissionDto,
  TradeType,
} from '@alpha/fx-dtos';
import { PaymentDto, PaymentStatus } from '@alpha/payments-dtos';
import { IconButton } from '@alpha/ui-lib/ui/button';
import { APDatePicker } from '@alpha/ui-lib/ui/DatePicker';
import {
  Box, Input, InputLabel, Typography,
} from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { ConfirmationModal } from '@alpha/ui-lib/ui/Modal/ConfirmationModal';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { faArrowDown, faArrowUp, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../../hooks/useLog';
import AuthService from '../../../../../services/Auth/auth.service';
import FXTradeService from '../../../../../services/FXTrade/fxTrade.service';
import { TStore } from '../../../../../store';
import { initiatePayment, initiateTrade } from '../../../../../store/authy/actions';
import { TAuthyState } from '../../../../../store/authy/reducer';
import AutocompleteDropDown from '../../../../AutocompleteDropDown/AutocompleteDropDown';
import BackdropLoader from '../../../Loaders/BackdropLoader/BackdropLoader';
import Rate from '../../Rate/Rate';
import { TSpotFXRequestForm } from '../formData';
import { TSpotDrawState } from '..';

import Actions from './Actions';
import DuplicatedTradeModal from './DuplicatedTradeModal';
import FundingMethod from './FundingMethod';
import { useStyles } from './index.styles';
import useInputSpotTrade from './useInputSpotTrade';
import useQuoteTradeRate from './useQuoteTradeRate';

interface IProps {
  form: FormikProps<TSpotFXRequestForm>;
  handleBlur?: (event: any) => void;
  setDrawerState: React.Dispatch<React.SetStateAction<TSpotDrawState>>;
  setBookedTrade: React.Dispatch<React.SetStateAction<ExecuteTradeResultDto | undefined>>;
  padAllowed?: boolean;
  firstPartyFlow: boolean;
  validatedTrade?: TradeSubmissionDto | TradeDto;
  setValidatedTrade: React.Dispatch<React.SetStateAction<
    TradeSubmissionDto | TradeDto | undefined>>;
  tradeDraft?: TradeDto;
  handleClose: () => void;
  submittingDraft: boolean;
  isTransfer: boolean;
  minSpotLimit: number
  maxSpotLimit: number,
}

// eslint-disable-next-line max-lines-per-function
const InputSpotTrade: React.FC<IProps> = ({
  form,
  handleBlur,
  setDrawerState,
  setBookedTrade,
  padAllowed = false,
  firstPartyFlow,
  validatedTrade,
  setValidatedTrade,
  tradeDraft,
  handleClose,
  submittingDraft,
  isTransfer = false,
  minSpotLimit,
  maxSpotLimit,
}) => {
  const styles = useStyles();
  const sb = useAlphaSnackbar();
  const dispatch = useDispatch();
  const { logError, logEvent } = useLog();
  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);
  const [activeIcon, setActiveIcon] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleChangeActive = () => {
    setActiveIcon((previousIcon) => !previousIcon);
  };

  const [mfaVerified, setMfaVerified] = useState<boolean>(false);
  const [openClearAllConfirmation, setOpenClearAllConfirmation] = useState(false);
  const [beneficiaryDrawerOpen, setBeneficiaryDrawerOpen] = useState<boolean>(false);
  const { isEMoneyDisabled } = useSwitchAccount();

  const {
    isLoading, isError, data,
  } = useQuery('getCurrencyAccounts', StatementsService.getStatementAccounts, {
    refetchOnWindowFocus: false,
  });

  const {
    allowedSellCurrenciesOption,
    allowedBuyCurrenciesOption,
    selectedSellCurrencyOption,
    setSelectedSellCurrencyOption,
    selectedBuyCurrencyOption,
    setSelectedBuyCurrencyOption,
    sellCurrencyTextFieldValue,
    setSellCurrencyTextFieldValue,
    buyCurrencyTextFieldValue,
    setBuyCurrencyTextFieldValue,
    fixedSide,
    setFixedSide,
    indicativeRate,
    getIndicativeRate,
    pageLoading,
    setPageLoading,
    rateLoading,
    currencySwitchRateLoading,
    updateCounterSideAmount,
    insufficientFunds,
    availableSpotDates,
    valueDatesLoading,
    swapCurrencies,
    handleClearAll,
    manualResetForm,
    dateAdjusted,
    setRequestedDate,
    setExcludedDates,
    setInitialDate,
    sellAmountInputRef,
  } = useInputSpotTrade(form);

  const handleSetSellAccount = (e: any) => {
    form.setFieldValue('selectedSellCurrencyAccount', data?.currencyAccounts.find((ca) => ca.id === e.target.value));
  };

  const handleSetBuyAccount = (e: any) => {
    form.setFieldValue('selectedBuyCurrencyAccount', data?.currencyAccounts.find((ca) => ca.id === e.target.value));
  };

  const calculateDisableDates = (_date: Moment) => {
    const formattedDate = _date.format('YYYY-MM-DD');
    return (!availableSpotDates?.includes(formattedDate));
  };

  const handleDateChange = (_date: Moment) => {
    if (_date) {
      form.setFieldValue('valueDate', _date.format('YYYY-MM-DD'));
    }
  };

  const {
    timer: timerForRate,
    liveRate,
    setLiveRate,
    liveRateStatus,
    setLiveRateStatus,
    quoteLiveRate,
  } = useQuoteTradeRate(setDisableSubmit, validatedTrade);

  const [duplicateWarningModal, setDuplicateWarningModal] = useState<boolean>(false);

  const handleTradeMfa = (tradeID: string) => {
    dispatch(initiateTrade({
      type: 'TRADE',
      id: tradeID,
    }));
  };

  const handleInputBlur = (event: any) => {
    try {
      if (event.target.value) {
        event.target.value = parseFloat(event.target.value.replace(/,/g, '')).toFixed(2);
        if (handleBlur) handleBlur(event);
      }
    } catch {
      if (handleBlur) handleBlur(event);
    }
  };

  const updateBuyAmountChanges = useCallback(
    debounce((valueStr: string, rate: number, buyCcy: string, sellCcy: string, valueDate: string) => {
      (async () => {
        setFixedSide('Buy');
        const value = parseFloat(valueStr.toString().replace(/,/g, '')) || 0.00;
        await form.setFieldTouched('buyAmount', true);

        if (sellCcy && buyCcy && rate) {
          updateCounterSideAmount('Buy', value, rate, buyCcy, sellCcy);
        }

        await form.setFieldValue('fixedAmount', value.toFixed(2));
        form.setFieldValue('fixedCurrencyCode', buyCcy);
        if (sellCcy
          && buyCcy
          && sellCcy !== ''
          && buyCcy !== ''
          && sellCcy !== buyCcy
          && value >= minSpotLimit
          && value <= maxSpotLimit
          && valueDate) {
          getIndicativeRate(sellCcy, buyCcy, 'Buy', value, valueDate);
          setRequestedDate(valueDate);
          setExcludedDates([]);
        }
      })();
    }, 300), [minSpotLimit, maxSpotLimit],
  );

  const updateSellAmountChanges = useCallback(
    debounce((valueStr: string, rate: number, buyCcy: string, sellCcy: string, valueDate: string) => {
      (async () => {
        setFixedSide('Sell');
        const value = parseFloat(valueStr.toString().replace(/,/g, '')) || 0.00;
        form.setFieldTouched('sellAmount', true, true);

        if (sellCcy && buyCcy && rate) { updateCounterSideAmount('Sell', value, rate, buyCcy, sellCcy); }

        form.setFieldValue('fixedAmount', value.toFixed(2));
        form.setFieldValue('fixedCurrencyCode', sellCcy);
        if (sellCcy
          && buyCcy
          && sellCcy !== ''
          && buyCcy !== ''
          && sellCcy !== buyCcy
          && value >= minSpotLimit
          && value <= maxSpotLimit
          && valueDate) {
          getIndicativeRate(sellCcy, buyCcy, 'Sell', value, valueDate);
          setRequestedDate(valueDate);
          setExcludedDates([]);
        }
      })();
    }, 300), [minSpotLimit, maxSpotLimit],
  );

  const handleBuyAmountChange = async (event: any) => {
    await form.setFieldValue('buyAmount', event.target.value, true);
    updateBuyAmountChanges(event.target.value, indicativeRate?.rate || 0,
      form.values.buyCurrencyCode, form.values.sellCurrencyCode, form.values.valueDate);
  };

  const handleSellAmountChange = async (event: any) => {
    await form.setFieldValue('sellAmount', event.target.value, true);
    updateSellAmountChanges(event.target.value, indicativeRate?.rate || 0,
      form.values.buyCurrencyCode, form.values.sellCurrencyCode, form.values.valueDate);
  };

  const handleValidateTrade = (): void => {
    setDisableSubmit(true);
    validateTrade(form.values);
  };

  useEffect(() => {
    const rate = liveRate ? liveRate.rate : indicativeRate?.rate;
    if (form.values.draftInitiated) {
      setFixedSide(form.values.fixedCurrencyCode === form.values.buyCurrencyCode ? 'Buy' : 'Sell');
    }

    if (liveRate && liveRate.rate) {
      form.setFieldValue('quoteRate', liveRate.rate);
    }

    if (liveRate && liveRate.buyAmount && liveRate.sellAmount) {
      // use buy amount / sell amount from quote endpoint
      form.setFieldValue('buyAmount', liveRate.buyAmount, true);
      form.setFieldValue('sellAmount', liveRate.sellAmount, true);
      return;
    }

    if ((rate !== 0 && rate !== undefined) && (form.values.sellCurrencyCode && form.values.buyCurrencyCode)) {
      // Indicative rate & if amount not returned
      if (fixedSide === 'Buy' && form.touched.buyAmount) {
        const value = +form.values.buyAmount.toString().replace(/,/g, '');
        updateCounterSideAmount('Buy', value, rate || 0,
          form.values.buyCurrencyCode, form.values.sellCurrencyCode);
      } else if (fixedSide === 'Sell' && form.touched.sellAmount) {
        const value = +form.values.sellAmount.toString().replace(/,/g, '');
        updateCounterSideAmount('Sell', value, rate || 0,
          form.values.buyCurrencyCode, form.values.sellCurrencyCode);
      }
    }
  }, [indicativeRate, liveRate]);

  const validateTrade = async (requestForm: TSpotFXRequestForm) => {
    setPageLoading(true);
    try {
      const tradeRequest: TradeRequest = {
        fundingMethod: requestForm.fundingMethod,
        sellCurrencyCode: requestForm.sellCurrencyCode,
        buyCurrencyCode: requestForm.buyCurrencyCode,
        fixedCurrencyCode: requestForm.fixedCurrencyCode,
        fixedAmount: requestForm.fixedAmount,
        valueDate: requestForm.valueDate,
        tradeType: TradeType.SPOT,
      };

      if (!tradeDraft) {
        const validatedTradeResult = await FXTradeService.postValidateTradeRequest(tradeRequest);
        setValidatedTrade(validatedTradeResult);

        setPageLoading(false);
        if (submittingDraft) {
          handleClose();
          sb.trigger(t('draft_spot_trade_submitted_successfully'), 'success');
          logEvent({ action: 'Successfully submitted draft spot trade' });
        } else if (validatedTradeResult.potentialDuplicates.length > 0) {
          setDuplicateWarningModal(true);
        } else {
          handleTradeMfa(validatedTradeResult.id);
        }
      } else {
        await FXTradeService.updateTrade(
          tradeDraft.id,
          {
            fundingMethod: requestForm.fundingMethod,
            valueDate: requestForm.valueDate,
          },
        );
        setPageLoading(false);
        setValidatedTrade(tradeDraft);
        handleTradeMfa(tradeDraft.id);
      }
    } catch (e) {
      sb.trigger(e.response?.data.error
        || e.message
        || t('sorry_we_could_not_validate_this_trade'), 'error');

      logError({ action: 'Error validating trade', error: e });
      setValidatedTrade(undefined);
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (liveRateStatus === 'failed') {
      setValidatedTrade(undefined);
      setMfaVerified(false);
    }
  }, [liveRateStatus]);

  useEffect(() => {
    if (authyState.status === 'SUCCESS' && authyState.type?.type === 'TRADE') {
      if (liveRateStatus !== 'loading') {
        handleSuccessfulAuthy();
      }
    }

    if (authyState.status === 'ERROR' && authyState.type?.type === 'TRADE') {
      setValidatedTrade(undefined);
      setMfaVerified(false);
    }

    if (authyState.status === undefined && authyState.type?.type === undefined && !mfaVerified) {
      setValidatedTrade(undefined);
      setDisableSubmit(false);
    }
  }, [authyState.status]);

  useEffect(() => {
    if (authyState.status === 'SUCCESS' && authyState.type?.type === 'PAYMENTS' && firstPartyFlow) {
      form.setFieldValue('paymentSubmitted', true);
      sb.trigger(t('spot_trade_has_been_booked_successfully'), 'success');
      setDrawerState('ConfirmTrade');
    }
  }, [authyState.status, authyState.type]);

  const handleSuccessfulAuthy = () => {
    setMfaVerified(true);
    setTimeout(() => { // Timeout to wait for trade status updated in DB
      setLiveRateStatus('loading');
      quoteLiveRate();
    }, 3000);
  };

  const handleBookTrade = async (): Promise<void> => {
    try {
      setLiveRateStatus('initiatedBookTrade');
      let executeTradeRequest: ExecuteTradeRequest;
      if (firstPartyFlow) {
        executeTradeRequest = {
          instructionId: String(liveRate?.instructionId),
          tradeDraftId: String(validatedTrade?.id),
          tradePayment: {
            beneficiaryId: form.values.beneficiary?.id ?? '',
            sellCurrencyAccountId: form.values.defaultSellCurrencyAccount?.id ?? '',
            sellCurrencyCode: form.values?.sellCurrencyCode,
            debitingCurrencyAccountId: form.values.defaultBuyCurrencyAccount?.id ?? '',
            debitingCurrencyCode: form.values.defaultBuyCurrencyAccount?.currencyCode ?? '',
            reference: form.values.reference,
            amount: parseFloat(form.values.buyAmount.toString().replace(/,/g, '')),
          },
        };
      } else {
        executeTradeRequest = {
          instructionId: String(liveRate?.instructionId),
          tradeDraftId: String(validatedTrade?.id),
        };
      }

      const bookingResult = await FXTradeService.postExecuteTradeBooking(executeTradeRequest);

      if (firstPartyFlow && bookingResult?.payment.id) {
        pollSinglePayment(6, 1000, bookingResult?.payment?.id);
      }
      setLiveRateStatus('successBookTrade');
      if (firstPartyFlow) {
        setBookedTrade(bookingResult.trade);
      } else {
        setBookedTrade(bookingResult);
      }
      if (!firstPartyFlow) { sb.trigger(t('spot_trade_has_been_booked_successfully'), 'success'); }
      logEvent({ action: 'Successfully booked spot trade' });
      logEvent({
        action: 'Book Trade Successfully',
        detail: {
          buyCurrency: bookingResult.buyCurrency,
          sellCurrency: bookingResult.sellCurrency,
        },
      });
      if (!firstPartyFlow) { setDrawerState('ConfirmTrade'); }
    } catch (error) {
      const errorMessage = error?.response?.data?.error || error.message || t('there_was_an_error_with_your_booking_please_request_new_live_rate');
      sb.trigger(errorMessage, 'error');
      logError({ action: 'Error booking trade', error });
      setLiveRateStatus('failedBookTrade');
    }
  };

  const rateType = () => (liveRateStatus === 'timeout'
    ? 'Expired'
    : 'Live Rate');

  const handleAcknowledgeDuplicate = () => {
    setDuplicateWarningModal(false);
    if (validatedTrade) {
      const acknowledgementRequest: UserAcknowledgmentRequest = {
        itemId: validatedTrade?.id,
        type: UserAcknowledgmentType.TRADE,
        content: {
          tradeId: validatedTrade?.id,
          potentialDuplicates: (validatedTrade as TradeSubmissionDto)?.potentialDuplicates,
          message: 'Spot trade potentially duplicated.',
        },
      };
      AuthService.postUserAcknowlegement(acknowledgementRequest);

      handleTradeMfa(validatedTrade.id);
    }
  };

  const setFormData = (id: string) => {
    const payment = { ...form.values, id };
    form.setFieldValue('payments', [payment]);
    logEvent({ action: 'Successfully added payment' });
  };

  const loadPaymentData = async (id: string): Promise<PaymentDto> => {
    try {
      const result = await PaymentsService.getSinglePayment(id);
      return result;
    } catch (error) {
      sb.trigger(error?.message || t('failed_to_load_payment'));
      logError({ action: 'Error loading payment', error });
      return [];
    }
  };

  const pollSinglePayment = async (retry = 6, delay = 1000, id: string) => {
    setPageLoading(true);
    const statusToPoll = [PaymentStatus.VALIDATED, PaymentStatus.ERRORED, PaymentStatus.INVALID];
    const record = await loadPaymentData(id);

    // eslint-disable-next-line max-len
    if (retry === 0 || record.status === PaymentStatus.INVALID || record.status === PaymentStatus.ERRORED) {
      if (record.errors.length) {
        record.errors.forEach((error) => {
          sb.trigger(
            `${error}`,
            'error',
            {
              persist: true,
              action: (key: string) => (
                <IconButton
                  icon={faTimes}
                  onClick={() => {
                    sb.close(key);
                  }}
                  style={{ color: '#fff', fontSize: '12px', display: 'inline-block' }}
                />
              ),
            },
          );

          logError({ action: `Error validating single payment - ${error}` });
        });
      } else {
        sb.trigger(`${t('there_was_an_error_validating_your_payment')}.`, 'error');
      }

      logError({ action: 'Error validating single payment' });
      setPageLoading(false);
      return;
    }
    await timer(delay);
    // eslint-disable-next-line max-len
    if (!statusToPoll.includes(record.status)) {
      pollSinglePayment(retry - 1, 1000, id);
    }
    // eslint-disable-next-line max-len

    if (record.status === PaymentStatus.VALIDATED) {
      dispatch(initiatePayment({
        type: 'PAYMENTS',
        approverOwn: true, // default set to true for first party clients
        paymentIds: [record.id],
        firstPartyFlow: true,
      }));
      setFormData(id);
    }
  };

  useEffect(() => {
    const {
      defaultBuyCurrencyAccount, defaultSellCurrencyAccount,
    } = form.values;

    if (defaultBuyCurrencyAccount !== undefined && form.values.buyCurrencyCode !== '') {
      form.setFieldValue('selectedBuyCurrencyAccount', defaultBuyCurrencyAccount);
    }

    if (defaultSellCurrencyAccount !== undefined && form.values.sellCurrencyCode !== '') {
      form.setFieldValue('selectedSellCurrencyAccount', defaultSellCurrencyAccount);
    }

    if (form.values.sellCurrencyCode === '') {
      form.setFieldValue('selectedSellCurrencyAccount', undefined);
      form.setFieldValue('defaultSellCurrencyAccount', undefined);
    }
    if (form.values.buyCurrencyCode === '') {
      form.setFieldValue('selectedBuyCurrencyAccount', undefined);
      form.setFieldValue('defaultBuyCurrencyAccount', undefined);
    }
  }, [form.values.defaultBuyCurrencyAccount, form.values.defaultSellCurrencyAccount,
    form.values.sellCurrencyCode, form.values.buyCurrencyCode]);

  const dynamicMarginLeft = (rateLoading || indicativeRate) ? '10px' : '33px';

  const amountWarning = form.values.fixedAmount === 0
  && form.values.buyCurrencyCode !== ''
  && form.values.sellCurrencyCode !== '' ? (
    <div style={{
      color: '#cf752f', fontWeight: 400, fontSize: '11px', marginTop: '4px', float: 'right',
    }}
    >
      <FontAwesomeIcon icon={faInfoCircle} />
      {' '}
      {t('You')}
      {' '}
      <strong>{t('must')}</strong>
      {' '}
      {t('input_an_amount')}
    </div>
    ) : <></>;

  const valueDateWarning = availableSpotDates
  && availableSpotDates.length > 0
  && !form.values.valueDate
  && form.values.fixedAmount !== 0
  && form.values.buyCurrencyCode !== ''
  && form.values.sellCurrencyCode !== ''
  && form.errors.buyAmount === undefined
  && form.errors.sellAmount === undefined ? (
    <div style={{
      color: '#cf752f', fontWeight: 400, fontSize: '11px', marginTop: '4px',
    }}
    >
      <FontAwesomeIcon icon={faInfoCircle} />
      {' '}
      {t('You')}
      {' '}
      <strong>{t('must')}</strong>
      {' '}
      {t('select_a_value_date')}
    </div>
    ) : <></>;

  const noAvailableValueDateWarning = !valueDatesLoading
    && availableSpotDates?.length === 0
    && form.errors.fixedAmount === undefined
    && form.errors.buyCurrencyCode === undefined
    && form.errors.sellCurrencyCode === undefined ? (
      <div style={{
        color: 'rgb(185, 86, 86)', fontWeight: 400, fontSize: '11px', marginTop: '4px',
      }}
      >
        <FontAwesomeIcon icon={faInfoCircle} />
        {' '}
        {t('No value dates available. Please contact your dealer.')}
      </div>
    ) : <></>;

  const styledText = (
    <span>
      <span style={{ fontWeight: 600 }}>{t('please_note^')}</span>
      {' '}
      <span style={{ fontWeight: 400 }}>{t('we_have_updated_the_value_date')}</span>
    </span>
  );

  return (
    <Box className={styles.form}>
      {pageLoading && (<BackdropLoader testId="backdrop-loader" />)}
      {!pageLoading && (
      <>
        <Box display="flex" justifyContent="space-between" flexDirection="column" className={styles.minHeight}>
          <Box padding="10px">
            <Box style={{ height: '100px' }}>
              <Box style={{ padding: '5px 0px 5px 20px', borderRadius: '10px', border: '1px solid #eee' }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: form.values.sellCurrencyCode ? '104px' : 'fit-content' }}>
                    <AutocompleteDropDown
                      hideFlag
                      backgroundColor="transparent"
                      name="sellCurrencyCode"
                      testId="bankCurrencyDropdown"
                      fontSize="22px"
                      fontWeight={600}
                      type="CODE"
                      capitalisedInput
                      options={selectedBuyCurrencyOption?.code ? allowedSellCurrenciesOption?.filter(
                        (item) => item.code !== selectedBuyCurrencyOption?.code,
                      ) : allowedSellCurrenciesOption.filter((item) => item.code !== 'CNY')}
                      setFieldValue={(field: string, value: any, shouldValidate?: boolean) => {
                        form.setFieldValue('selectedSellCurrencyAccount', undefined);
                        form.setFieldValue(field, value, shouldValidate);

                        form.setFieldValue('sellAmount', 0);
                        if (value === '' || value === undefined) {
                          manualResetForm();
                        } else {
                          form.setFieldValue('buyAmount', 0);
                          form.setFieldValue('valueDate', null);
                          form.validateForm();
                        }
                      }}
                      value={form.values.sellCurrencyCode}
                      touched={form.touched.sellCurrencyCode}
                      currentOption={selectedSellCurrencyOption}
                      setCurrentOption={setSelectedSellCurrencyOption}
                      textFieldValue={sellCurrencyTextFieldValue}
                      setTextFieldValue={setSellCurrencyTextFieldValue}
                      disabled={Boolean(validatedTrade) || form.values.draftInitiated}
                      placeholder={t('sell_currency')}
                    />
                  </div>
                  <div style={{ display: 'flex', width: '50%', paddingRight: '30px' }}>
                    <NumberFormat
                      thousandSeparator
                      className={clsx(
                        styles.red,
                      )}
                      id="sellAmount"
                      name="sellAmount"
                      value={form.values.sellAmount && form.values.sellCurrencyCode !== '' ? form.values.sellAmount : ''}
                      disabled={Boolean(validatedTrade) || form.values.draftInitiated
                              || currencySwitchRateLoading || (form.values.buyCurrencyCode === '' || form.values.sellCurrencyCode === '')}
                      onChange={handleSellAmountChange}
                      onBlurCapture={handleInputBlur}
                      disableUnderline
                      autoComplete="off"
                      placeholder="0.00"
                      type="text"
                      customInput={Input}
                      inputProps={{
                        'data-testid': 'sellAmount',
                        'font-size': '22px',
                        style: { textAlign: 'right' },
                      }}
                      style={{ flexGrow: 1, marginTop: '10px' }}
                      decimalScale={2}
                      fixedDecimalScale
                      onKeyDown={(e) => { if (e.key === '-') e.preventDefault(); }}
                      inputRef={sellAmountInputRef}
                    />
                  </div>
                </Box>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: form.values.selectedSellCurrencyAccount ? '50%' : '207px' }}>
                    <Dropdown
                      data={filterByCurrencyAccountToData(data?.currencyAccounts ?? [], false, false, form.values.sellCurrencyCode)}
                      handleChange={(e) => handleSetSellAccount(e)}
                      helperText=""
                      placeholderText={`${t('account')} | 0.00`}
                      data-testid="sell-account"
                      selectName="sell-account"
                      selectValue={form.values.selectedSellCurrencyAccount?.id
                        ? form.values.selectedSellCurrencyAccount?.id : form.values?.defaultSellCurrencyAccount?.id}
                      hideFlag
                      backgroundColor="transparent"
                      disabled={!form.values.sellCurrencyCode || mfaVerified}
                      fx
                    />
                  </div>
                  <div style={{ width: '50%', padding: '15px 30px 0px 0px' }}>
                    <InputLabel className={clsx(styles.error, styles.red)}>
                      {form.touched.sellAmount ? form.errors.sellAmount : ''}
                    </InputLabel>
                  </div>
                </div>
              </Box>
            </Box>

            <div style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%',
            }}
            >
              {(rateLoading || (!validatedTrade && form.values.buyCurrencyCode
              && form.values.sellCurrencyCode && indicativeRate?.rate && form.values?.fixedAmount > 0
              && (form.values.fixedAmount >= minSpotLimit && form.values.fixedAmount <= maxSpotLimit)))
              && (
              <Rate
                type={!validatedTrade ? 'Indicative' : rateType()}
                creditingCurrency={selectedSellCurrencyOption?.code || ''}
                debitingCurrency={selectedBuyCurrencyOption?.code || ''}
                rate={indicativeRate ? indicativeRate.rate : 0.00}
                inverseRate={indicativeRate ? indicativeRate.inverseRate : 0.00}
                loading={rateLoading}
                mfaVerified={mfaVerified}
              />
              )}
              {validatedTrade && (
              <Rate
                type={liveRate ? rateType() : 'Indicative'}
                creditingCurrency={selectedSellCurrencyOption?.code || ''}
                debitingCurrency={selectedBuyCurrencyOption?.code || ''}
                rate={liveRate?.rate || indicativeRate?.rate || 0.00}
                inverseRate={liveRate?.inverseRate || indicativeRate?.inverseRate || 0.00}
                loading={liveRateStatus === 'loading' || liveRateStatus === 'initiated'}
                mfaVerified={mfaVerified}
              />
              )}

              <div style={{ marginLeft: dynamicMarginLeft }}>
                <IconButton
                  title="Swap"
                  icon={activeIcon ? faArrowUp : faArrowDown}
                  size="medium"
                  aria-label="Swap"
                  disabled={
                    mfaVerified
                    || (!form.values.sellCurrencyCode || !form.values.buyCurrencyCode)
                    || rateLoading
                    || form.values.draftInitiated
                  }
                  onClick={() => {
                    handleChangeActive();
                    swapCurrencies();
                  }}
                  className={mfaVerified ? styles.hideSwapBtn : styles.swapButton}
                />
              </div>
            </div>

            <Box style={{ marginTop: '-8px' }}>
              <Box style={{ padding: '5px 0px 5px 20px', borderRadius: '10px', border: '1px solid #eee' }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: form.values.buyCurrencyCode ? '104px' : 'fit-content' }}>
                    <AutocompleteDropDown
                      hideFlag
                      fontSize="22px"
                      fontWeight={600}
                      backgroundColor="transparent"
                      name="buyCurrencyCode"
                      testId="bankCurrencyDropdown"
                      type="CODE"
                      capitalisedInput
                      options={selectedSellCurrencyOption?.code ? allowedBuyCurrenciesOption?.filter(
                        (item) => item.code !== selectedSellCurrencyOption?.code,
                      ) : allowedBuyCurrenciesOption.filter((item) => item.code !== 'CNY')}
                      setFieldValue={(field: string, value: any, shouldValidate?: boolean) => {
                        form.setFieldValue('selectedBuyCurrencyAccount', undefined);
                        form.setFieldValue(field, value, shouldValidate);

                        form.setFieldValue('buyAmount', 0);

                        if (value === '' || value === undefined) {
                          manualResetForm();
                        } else {
                          form.setFieldValue('sellAmount', 0);
                          form.setFieldValue('valueDate', null);
                          form.validateForm();
                        }
                      }}
                      value={form.values.buyCurrencyCode}
                      touched={form.touched.buyCurrencyCode}
                      errors={form.errors.buyCurrencyCode}
                      currentOption={selectedBuyCurrencyOption}
                      setCurrentOption={setSelectedBuyCurrencyOption}
                      textFieldValue={buyCurrencyTextFieldValue}
                      setTextFieldValue={setBuyCurrencyTextFieldValue}
                      disabled={Boolean(validatedTrade) || form.values.draftInitiated}
                      placeholder={t('buy_currency')}
                    />
                  </div>
                  <div style={{ display: 'flex', width: '50%', paddingRight: '30px' }}>
                    <NumberFormat
                      thousandSeparator
                      customInput={Input}
                      className={styles.green}
                      id="buyAmount"
                      name="buyAmount"
                      value={form.values.buyAmount && form.values.buyCurrencyCode !== '' ? form.values.buyAmount : ''}
                      disabled={Boolean(validatedTrade) || form.values.draftInitiated
                        || currencySwitchRateLoading || (form.values.buyCurrencyCode === '' || form.values.sellCurrencyCode === '')}
                      onChange={handleBuyAmountChange}
                      onBlurCapture={handleInputBlur}
                      disableUnderline
                      autoComplete="off"
                      placeholder="0.00"
                      type="text"
                      inputProps={{
                        'data-testid': 'buyAmount',
                        'font-size': '22px',
                        style: { textAlign: 'right' },
                      }}
                      style={{ flexGrow: 1, marginTop: '10px' }}
                      decimalScale={2}
                      fixedDecimalScale
                      onKeyDown={(e) => { if (e.key === '-') e.preventDefault(); }}
                    />
                  </div>
                </Box>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: form.values.selectedBuyCurrencyAccount ? '50%' : '207px' }}>
                    <Dropdown
                      data={filterByCurrencyAccountToData(data?.currencyAccounts ?? [], false, false, form.values.buyCurrencyCode)}
                      handleChange={(e) => handleSetBuyAccount(e)}
                      helperText=""
                      placeholderText={`${t('account')} | 0.00`}
                      data-testid="buy-account"
                      selectName="buy-account"
                      selectValue={form.values.selectedBuyCurrencyAccount?.id
                        ? form.values.selectedBuyCurrencyAccount?.id : form.values.defaultBuyCurrencyAccount?.id}
                      hideFlag
                      backgroundColor="transparent"
                      disabled={!form.values.buyCurrencyCode || mfaVerified}
                      fx
                    />
                  </div>
                  <div style={{ width: '50%', padding: '15px 30px 0px 0px' }}>
                    <InputLabel className={clsx(styles.error, styles.red)}>
                      {form.touched.buyAmount ? form.errors.buyAmount : ''}
                    </InputLabel>
                  </div>
                </div>
              </Box>
              {amountWarning}
              {!validatedTrade && !mfaVerified ? (
                <Box>
                  <Box style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between' }}>
                    <Box>
                      <Box className={styles.valueDateTitle} gridGap={3}>
                        {t('value_date')}
                        <APTooltip
                          placement="right"
                          padding='8px'
                          title={
                            (
                              <div style={{
                                width: '314px',
                                height: '40px',
                                fontWeight: 400,
                                fontSize: '12px',
                                color: '#F5EFF7',
                                lineHeight: '16px',
                              }}
                              >
                                <span style={{ whiteSpace: 'break-spaces' }}>
                                  {t('you_can_select_the_value_for_your_spot_trade_up_to_2_days_into_the_future')}
                                </span>
                              </div>
                            )
                          }
                        >
                          <Box display="flex"><FontAwesomeIcon icon={faInfoCircle as IconProp} className={styles.icon} /></Box>
                        </APTooltip>
                        {valueDatesLoading && <Loader testId="valueDateLoading" size={8} />}
                      </Box>
                      <APDatePicker
                        fullWidth
                        label=""
                        autoOk
                        language={i18n.language}
                        className="datePicker"
                        onChange={handleDateChange}
                        value={form.values.valueDate || null} // null to fix error message shown when empty
                        loadingIndicator={<Loader testId="" />}
                        disabled={
                          valueDatesLoading
                          || availableSpotDates?.length === 0
                          || form.values.buyCurrencyCode === ''
                          || form.values.sellCurrencyCode === ''
                          || !form.values.selectedBuyCurrencyAccount
                          || !form.values.selectedSellCurrencyAccount
                        }
                        placeholder={t('select_value_date')}
                        shouldDisableDate={calculateDisableDates}
                        style={{ width: '258px', borderRadius: '4px' }}
                        onOpen={() => {
                          // if the user intentionally tries to view/change the date, then show the adjustment message upon date adjustment
                          setInitialDate(false);
                        }}
                      />
                      {valueDateWarning}
                      {noAvailableValueDateWarning}
                    </Box>
                  </Box>
                  {dateAdjusted && (
                  <div style={{ marginTop: '16px' }}>
                    <Alert
                      className={styles.warningAlert}
                      variant="warning"
                      text={styledText}
                    />
                  </div>
                  )}
                </Box>
              ) : null}
              <div style={{ marginTop: '-9px' }}>
                <FundingMethod
                  form={form}
                  padAllowed={padAllowed}
                  show={form.values.buyAmount !== 0 || form.values.sellAmount !== 0}
                  editable={!(validatedTrade && mfaVerified)}
                  insufficientFunds={insufficientFunds()}
                  isTransfer={isTransfer}
                  firstPartyFlow={firstPartyFlow}
                  validatedTrade={!!validatedTrade}
                />
              </div>
            </Box>
          </Box>
          {firstPartyFlow && !mfaVerified && (
          <Box style={{ padding: '10px' }}>
            <div style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px',
            }}
            >
              <Typography variant="subtitle1">{t('beneficiary')}</Typography>
              <Authorization requiredRoles={[[UserRole.BENEFICIARY_INPUTTER]]}>
                <Typography
                  variant="subtitle1"
                  style={{ color: '#1E8777', textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => setBeneficiaryDrawerOpen(true)}
                >
                  {t('create_new?')}
                </Typography>
              </Authorization>
            </div>
            <BeneficiaryDropdownContainer
              currencyCode={form.values.defaultBuyCurrencyAccount?.currencyCode || ''}
              beneficiary={form.values.beneficiary}
              setFieldValue={form.setFieldValue}
            />
            <div style={{ marginTop: '20px' }}>
              <InputAndLabel
                disabled={!form.values.buyCurrencyCode && !form.values.sellCurrencyCode}
                label={t('reference_(drawdown)')}
                name="reference"
                id="reference"
                testId="reference"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.reference}
                error={
                  form.touched.reference
                  && Boolean(form.errors.reference)
                }
                helperText={
                  form.touched.reference
                  && form.errors.reference
                }
              />
              {form.values.fundingMethod === TradeFundingMethod.WIRE_TRANSFER && firstPartyFlow
                && (
                <Box style={{ marginTop: '20px' }}>
                  <div>
                    <Alert
                      className={styles.alert}
                      variant="info"
                      text={t('first_party_spot_info')}
                    />
                  </div>
                </Box>
                )}
            </div>
          </Box>
          )}
          <Actions
            timerForRate={timerForRate}
            form={form}
            setValidatedTrade={setValidatedTrade}
            setMfaVerified={setMfaVerified}
            setLiveRate={setLiveRate}
            mfaVerified={mfaVerified}
            handleValidateTrade={handleValidateTrade}
            disableSubmit={disableSubmit}
            setDisableSubmit={setDisableSubmit}
            liveRateStatus={liveRateStatus}
            pageLoading={pageLoading}
            rateLoading={rateLoading}
            valueDateLoading={valueDatesLoading}
            handleBookTrade={handleBookTrade}
            quoteLiveRate={quoteLiveRate}
            insufficientFunds={insufficientFunds()}
            validatedTrade={validatedTrade}
            submittingDraft={submittingDraft}
            handleClearAll={() => {
              setOpenClearAllConfirmation(true);
            }}
          />
        </Box>

        <DuplicatedTradeModal
          open={duplicateWarningModal}
          onClose={() => { setDuplicateWarningModal(false); }}
          potentialDuplicates={(validatedTrade as TradeSubmissionDto)?.potentialDuplicates || []}
          handleAcknowledgeDuplicate={handleAcknowledgeDuplicate}
        />
        <ConfirmationModal
          variant="negative"
          bothButtons
          title={(
            <span>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="lg"
                color="#CF752F"
                className="titleIcon"
              />
              {' '}
              <span style={{ marginLeft: '4px', marginTop: '2px' }}>
                {' '}
                {t('are_you_sure')}
              </span>
            </span>
          )}
          open={openClearAllConfirmation}
          onExit={() => setOpenClearAllConfirmation(false)}
          onConfirm={() => {
            handleClearAll();
            setOpenClearAllConfirmation(false);
            sb.trigger(t('cleared_all_input'), 'info');
          }}
          exitButtonText={t('cancel')}
          content={(
            <p style={{
              fontSize: '13px', fontWeight: 400, color: '#212529BF',
            }}
            >
              {t('you_will_be_clearing_all_input_from_this_form')}
            </p>
          )}
          width="600px"
          buttonSpacing="space-between"
          confirmButtonWidth={458}
          cancelButtonWidth={44}
          confirmButtonText={t('clear')}
        />
        <PaymentContext.Provider value={{
          currencyCode: form?.values?.defaultBuyCurrencyAccount?.currencyCode,
          currency: form?.values?.defaultBuyCurrencyAccount?.currency,
          ...form,
        }}
        >
          <CreateBeneficiary
            open={beneficiaryDrawerOpen}
            header="Create New Beneficiary"
            handleDrawerClose={() => setBeneficiaryDrawerOpen(false)}
            firstPartyFlow={isEMoneyDisabled()}
          />
        </PaymentContext.Provider>
      </>
      )}
    </Box>
  );
};

export default InputSpotTrade;
