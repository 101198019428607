/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import IATDrawerContainer from 'components/Drawer/IATDrawer/IATDrawerContainer';
import CreateForwardTradeDrawer from 'components/Molecules/Trade/CreateForwardTradeDrawer';
import useAuthorization from 'hooks/useAuthorization';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';
import useSwitchAccount from 'hooks/useSwitchAccount';
import { useQuery } from 'react-query';
import routes from 'routes.path';
import { FeatureFlagName } from 'services/Config/config.service';
import browserHistory from 'services/history/browserHistory';
import t from 'utils/translationHelper';

import { TradeDto, TradeStatus } from '@alpha/fx-dtos';
import { DashboardUserAccountPreferencesDto } from '@alpha/profile-dtos';
import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { Box, Button } from '@alpha/ui-lib/ui/external';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDown,
  faArrowUp, faCalendarAlt,
  faList, faMoneyBill, faRandom, faRightLeft, faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CreatePaymentDrawer from '../../../components/Molecules/Payments/CreatePaymentDrawer';
import CreateSpotDrawer from '../../../components/Molecules/Trade/CreateSpotDrawer';
import Authorization from '../../../hocs/Authorization';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import useLog from '../../../hooks/useLog';
import useProfile from '../../../hooks/useProfile';
import useQueryString from '../../../hooks/useQueryString';
import { UserRole } from '../../../models/user';
import FXTradeService from '../../../services/FXTrade/fxTrade.service';
import isFxMarketClosed from '../../../utils/fxTrades/isFxMarketClosed';
import CreateBeneficiary from '../../Beneficiaries/CreateBeneficiary';
import RightAlignedCta from '../RightAlignedCta/RightAlignedCta';

import PaymentButton from './PaymentButton/PaymentButton';
import useStyles from './index.styles';

interface IQuickActionsProps {
  dashboardSettings?: DashboardUserAccountPreferencesDto;
  setDashboardSettings?:
  React.Dispatch<React.SetStateAction<DashboardUserAccountPreferencesDto | undefined>>;
  viewablePages?: string[];
}
const QuickActions: React.FC<IQuickActionsProps> = (
  { dashboardSettings, setDashboardSettings, viewablePages }: IQuickActionsProps,
) => {
  const fxMarketClosed = isFxMarketClosed();
  const classes = useStyles({ fxMarketClosed });
  const user = useProfile();
  const { logEvent, logError } = useLog();
  const sb = useAlphaSnackbar();
  const [createPaymentsDrawerOpen,
    setCreatePaymentsDrawerOpen] = useState(false);
  const [createBeneficiaryOpen,
    setCreateBeneficiaryOpen] = useState(false);
  const [createSpotDrawerOpen,
    setCreateSpotDrawerOpen] = useState(false);
  const [createForwardTradeDrawerOpen,
    setCreateForwardTradeDrawerOpen] = useState(false);
  const [bookTradeAction] = useQueryString('booktrade', undefined);
  const [tradeDraft,
    setTradeDraft] = useState<TradeDto>();
  const [, setFxLastUpdatedTime] = useState<string>();
  const [openIATDrawer, setOpenIATDrawer] = useState(false);
  const { isEMoneyDisabled } = useSwitchAccount();
  const [openSpotMenu, setOpenSpotMenu] = useState<null | HTMLElement>(null);
  const [firstPartyFlow, setFirstPartyFlow] = useState(false);
  const { isFlagActive } = useGetFeatureFlags();
  const { authorized: isForwardInputter } = useAuthorization(
    [[UserRole.FORWARD_INPUTTER]],
  );
  const { authorized: hasSpotRoles } = useAuthorization(
    [[UserRole.SPOT, UserRole.SPOT_INPUTTER]],
  );
  const [fxAllowed, setFxAllowed] = useState<boolean>();
  const getAccountConfiguration = async () => {
    try {
      const result = await FXTradeService.getAccountConfiguration();
      setFxAllowed(result.fxAllowed);
    } catch (e) {
      sb.trigger(e?.message || t('could_not_load_account_configuration_data'));
      logError({ action: 'Error loading account configuration', error: e });
    }
  };

  useEffect(() => {
    getAccountConfiguration();
  }, []);

  const paymentFileMenuOptions = [
    {
      content: t('create_manual_payments'),
      icon: faList,
      onClick: () => {
        setCreatePaymentsDrawerOpen(true);
        logEvent({ action: 'Click create manual payment' });
      },
      disableHoverEffect: true,
      disableClick: true,
    },
    {
      content: t('payment_upload'),
      icon: faArrowUp,
      onClick: () => {
        logEvent({ action: 'Click Payment Upload' });
        browserHistory.push(routes.transactions.paymentUpload);
      },
    },
  ];

  const IATMenuOptions = [
    {
      content: t('inter_account_transfer'),
      icon: faRightLeft,
      onClick: () => {
        logEvent({ action: 'Click Inter Account Transfer' });
        setOpenIATDrawer(true);
      },
    },
  ];

  const newPaymentMenuOptions = [
    {
      content: t('new_payment~'),
      icon: faMoneyBill,
      onClick: () => {
        logEvent({ action: 'Click New Payment' });
        setCreatePaymentsDrawerOpen(true);
      },
    },
  ];

  const spotTradeMenuOptions = [
    {
      content: t('new_spot_trade'),
      icon: faArrowDown,
      onClick: () => {
        setFirstPartyFlow(false);
        setCreateSpotDrawerOpen(true);
      },
    },
    // {
    //   content: t('new_first_party_spot'),
    //   icon: faArrowDown,
    //   onClick: () => {
    //     setFirstPartyFlow(true);
    //     setCreateSpotDrawerOpen(true);
    //   },
    // },
  ];

  const forwardTradeOption = [
    {
      content: t('new_forward_trade'),
      icon: faCalendarAlt,
      onClick: () => {
        setCreateForwardTradeDrawerOpen(true);
      },
    },
  ];

  const menuOptions = {
    newPaymentMenuOptions,
    paymentFileMenuOptions,
    IATMenuOptions,
  };

  useEffect(() => {
    const openSpotTradeDrawer = async (tradeId: string) => {
      try {
        const submittedTrade = await FXTradeService.getTradeData(tradeId);
        if (submittedTrade && submittedTrade.status === TradeStatus.SUBMITTED) {
          setTradeDraft(submittedTrade);
          setCreateSpotDrawerOpen(true);
        }
      } catch (e) {
        sb.trigger(e?.message || t('there_is_an_error_loading_trade_information'), 'error');
        logError({ action: 'Error loading trade information', error: e });
      }
    };

    if (bookTradeAction) {
      openSpotTradeDrawer(bookTradeAction);
    }
  }, [bookTradeAction]);

  useEffect(() => {
    if (createBeneficiaryOpen) {
      setCreatePaymentsDrawerOpen(false);
    }
  }, [createBeneficiaryOpen]);

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.newTitle}>
          <span style={{ fontWeight: 400 }}>
            {t('welcome_back~')}
            ,
            <span style={{ fontWeight: 600 }}>
              {user.userProfile?.firstName ? ` ${user.userProfile?.firstName}` : ''}
              {' '}
            </span>
          </span>

        </Box>
        <Box className={classes.buttons}>
          {!isEMoneyDisabled() ? (
            <PaymentButton
              menuOptions={menuOptions}
              roles={user?.userProfile?.roles}
              setOpenIATDrawer={setOpenIATDrawer}
            />
          ) : null}
          <Authorization requiredRoles={[[UserRole.BENEFICIARY_INPUTTER]]}>
            <Button
              disableFocusRipple
              className={classes.actionButton}
              onClick={() => {
                setCreateBeneficiaryOpen(true);
                logEvent({ action: 'Quick action - New Beneficiary' });
              }}
            >
              {' '}
              <FontAwesomeIcon icon={faUsers as IconProp} />
              <br />
              {t('new_beneficiary~')}
            </Button>
          </Authorization>
          {!isEMoneyDisabled() && fxAllowed && (
            <Authorization requiredRoles={[[UserRole.SPOT, UserRole.SPOT_INPUTTER]]}>
              <Box className={classes.paymentUploadActionButtonWrapper}>
                <APTooltip
                  placement="bottom-end"
                  arrow
                  title={isFxMarketClosed() ? t('fx_execution_is_not_available_at_this_time1') : ''}
                >
                  <Box display="flex">
                    {isForwardInputter
                      ? (
                        <APMenu
                          buttonProps={{ disabled: isFxMarketClosed() }}
                          open={Boolean(openSpotMenu)}
                          anchorEl={openSpotMenu}
                          setOpen={setOpenSpotMenu}
                          disableBackdropClick={false}
                          hideIcon
                          buttonContent={(
                            <>
                              <div>
                                <FontAwesomeIcon icon={faRandom as IconProp} />
                              </div>
                              {' '}
                              <div>{t('new_trade')}</div>
                            </>
                          )}
                        >
                          {spotTradeMenuOptions.concat(forwardTradeOption).map((
                            item,
                          ) => <MenuDropdownItem setOpen={setOpenSpotMenu}>{item}</MenuDropdownItem>)}
                        </APMenu>
                      ) : (
                        <Button
                          disableFocusRipple
                          disabled={isFxMarketClosed()}
                          className={classes.actionButton}
                          onClick={() => {
                            setCreateSpotDrawerOpen(true);
                          }}
                        >
                          {' '}
                          <FontAwesomeIcon icon={faRandom as IconProp} />
                          <br />
                          {t('new_spot_trade~')}
                        </Button>
                      )}
                  </Box>
                </APTooltip>
              </Box>
            </Authorization>
          )}
          {isEMoneyDisabled() && fxAllowed && (
            <Authorization requiredRoles={[[UserRole.SPOT]]}>
              <APTooltip
                placement="bottom-end"
                arrow
                title={isFxMarketClosed() ? t('fx_execution_is_not_available_at_this_time1') : ''}
              >
                <Box display="flex">
                  <Button
                    disableFocusRipple
                    className={classes.actionButton}
                    disabled={isFxMarketClosed()}
                    onClick={() => {
                      setFirstPartyFlow(true);
                      setCreateSpotDrawerOpen(true);
                      logEvent({ action: 'Quick action - New Spot Trade' });
                    }}
                  >
                    <FontAwesomeIcon icon={faRandom as IconProp} />
                    <br />
                    {t('new_spot_trade~')}
                  </Button>
                </Box>
              </APTooltip>
            </Authorization>
          )}
          {!isEMoneyDisabled() && fxAllowed && !hasSpotRoles && isForwardInputter && (
            <Authorization requiredRoles={[[UserRole.FORWARD_INPUTTER]]}>
              <APTooltip
                placement="bottom-end"
                arrow
                title={isFxMarketClosed() ? t('fx_execution_is_not_available_at_this_time1') : ''}
              >
                <Box display="flex">
                  <Button
                    disableFocusRipple
                    className={classes.actionButton}
                    disabled={isFxMarketClosed()}
                    onClick={() => {
                      setCreateForwardTradeDrawerOpen(true);
                      logEvent({ action: 'Quick action - New Forward Trade' });
                    }}
                  >
                    <FontAwesomeIcon icon={faRandom as IconProp} />
                    <br />
                    {t('new_forward_trade')}
                  </Button>
                </Box>
              </APTooltip>
            </Authorization>
          )}
          <RightAlignedCta
            dashboardSettings={dashboardSettings}
            setDashboardSettings={setDashboardSettings}
            viewablePages={viewablePages}
          />
        </Box>
      </Box>

      {/* Drawers */}
      <CreatePaymentDrawer
        open={createPaymentsDrawerOpen}
        onClose={() => {
          setCreatePaymentsDrawerOpen(false);
        }}
        triggerSpotOpen={{
          setOpen: setCreateSpotDrawerOpen,
          setTradeDraft,
        }}
        setCreateBeneficiaryOpen={setCreateBeneficiaryOpen}
      />

      <CreateSpotDrawer
        open={createSpotDrawerOpen}
        onClose={() => {
          setCreateSpotDrawerOpen(false);
        }}
        onTradeBooked={() => {
          setFxLastUpdatedTime(Date.now().toString());
        }}
        tradeDraft={tradeDraft}
        setTradeDraft={setTradeDraft}
        heading={firstPartyFlow ? t('first_party_spot') : t('new_spot_trade')}
        firstPartyFlow={firstPartyFlow}
      />

      <CreateForwardTradeDrawer
        open={createForwardTradeDrawerOpen}
        onClose={() => {
          setCreateForwardTradeDrawerOpen(false);
        }}
        onTradeBooked={() => {
          setFxLastUpdatedTime(Date.now().toString());
        }}
        tradeDraft={tradeDraft}
        setTradeDraft={setTradeDraft}
        heading={t('new_forward_trade')}
      />

      <CreateBeneficiary
        firstPartyFlow={isEMoneyDisabled()}
        open={createBeneficiaryOpen}
        handleDrawerClose={() => {
          setCreateBeneficiaryOpen(false);
        }}
      />
      <IATDrawerContainer open={openIATDrawer} onClose={() => setOpenIATDrawer(false)} />
    </>
  );
};

export default QuickActions;
